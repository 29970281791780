import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { BaseButton } from "src/components";
import usePostMessage from "src/pages/hooks/post-message";

// 회원탈퇴 완료
const WithdrawalComplete = () => {
  const navigate = usePartnerNavigate();
  const { postMessageRevoke } = usePostMessage();
  const handleClick = () => {
    if (window.ReactNativeWebView) {
      postMessageRevoke();
    } else {
      navigate("/court/main");
    }
  };
  return (
    <div className="withdrawal-page">
      <div className="base-detail-layout complete-layout">
        <h2>회원탈퇴가 완료되었습니다</h2>
        <p className="desc">이용해주셔서 감사합니다</p>
      </div>
      <div className="btn-wrap">
        <BaseButton title="확인" className="base-btn" onClick={handleClick}></BaseButton>
      </div>
    </div>
  );
};

export default WithdrawalComplete;
