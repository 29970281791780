import Link from "src/components/PartnerLink";
import { routeList } from "src/routes/routes";
import { route } from "src/vars";
import useBreadcrumbs from "use-react-router-breadcrumbs";

/**
 * App > Container > Layout > BreadCrumbs
 */
function BreadCrumbs() {
  const breadcrumbs = useBreadcrumbs(routeList);

  const filteredBreadcrumbs = breadcrumbs.filter(
    (crumb) =>
      crumb.key.startsWith("/court/login") ||
      crumb.key.startsWith("/court/mypage/contracts") ||
      crumb.key.startsWith("/court/footer") ||
      crumb.key.startsWith("/court/error"),
  );
  // Taap 앱 진입을 위한 페이지
  if (window.location.pathname.startsWith(`${route.basename}/taap`)) {
    return <div className="empty-bread-crumbs"></div>;
  }

  if (filteredBreadcrumbs.length === 0) {
    return null;
  }
  return (
    <div className="bread-crumbs">
      {filteredBreadcrumbs.map(({ match, breadcrumb }, index) => {
        return (
          <span key={index}>
            {index < filteredBreadcrumbs.length - 1 ? <Link to={match.pathname}>{breadcrumb}</Link> : breadcrumb}
            {/* // <Link to={match.pathname}>{breadcrumb}</Link> */}
            {index < filteredBreadcrumbs.length - 1 && " > "}
          </span>
        );
      })}
    </div>
  );
}

export default BreadCrumbs;
