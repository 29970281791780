import moment from "moment";
import "moment/locale/ko";
import { useNavigate, useParams } from "react-router-dom";
import { ContractPaymentModel } from "src/api/contract/contract-types";
import { numberToStringWithComma } from "src/utils/common-util";
import { ActiveTab } from "../payment-types";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";

type Props = {
  activeTab: ActiveTab;
  payment: ContractPaymentModel;
  // payment: any;
};

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 결제 내역 / 연체 내역 화면 > 목록 아이템 컴포넌트
 */
const PaymentItem = ({ activeTab, payment }: Props) => {
  let paymentView: any = payment;
  const navigate = usePartnerNavigate();
  // path variable 계약 id
  const { contractId } = useParams();
  console.log(`payment`, payment);
  return (
    <div className="contents-card">
      <div className="contents-card__title-area">
        <p>{moment(paymentView.transactionDate).locale("ko").format("YYYY.MM.DD(ddd)")}</p>

        <div className="contents-card__info-area ma0">
          <div className="flex-center-end">
            <span className={`base-chip ${paymentView.paymentCancel && "color-gray"}`}>{!paymentView.paymentCancel ? "결제완료" : "결제취소"}</span>
          </div>
        </div>
      </div>

      <div className="contents-card__info-area">
        <p>
          {/* {payment?.paymentLabel} */}
          {activeTab === "OVERDUE" && " 연체 금액"}
          {paymentView.productName}
          {/* {moment(payment?.payment.transactionDate).format("M")}월 이용료{activeTab === "OVERDUE" && " 연체 금액"} */}
        </p>
        <div className={`value`}>
          <div className="flex-center">
            <span className="">{numberToStringWithComma(Number(paymentView.totalAmount || 0))}</span> 원
            <button
              type="button"
              onClick={() => {
                // 결제내역/연체내역 상세화면으로 이동
                // TODO: 우선 상세조회 API 가 없어서 state 로 전달. 상세조회 API 가 생기면 state 제거필요
                const pathId = paymentView.paymentCancel ? paymentView.paymentCancel.paymentId : paymentView.paymentId;
                navigate(`/court/mypage/contracts/${contractId}/payments/${pathId}`, { state: paymentView });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentItem;
