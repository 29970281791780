import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useCallback, useState } from "react";
import { CourtAuthError, useCourtAuth } from "src/components/court-auth";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useRefreshTokenErrorModal } from "src/recoil/refreshTokenErrorModal/hook";
import log from "src/utils/log";
import { usePartnerNavigate } from "./usePartnerNavigate";

type ApiOperation<I, O> = (axios: AxiosInstance, params?: I) => Promise<O>;

type Options = {
  noAuthenticationRequired?: boolean; // 인증 필요없을 경우 true
  noHandleError?: boolean; // api operation 에서 에러 핸들하는것을 원하지 않을 경우 true
  anotherHandleError?: boolean;
};

export default function useApiOperation<I, O>(
  apiOperation: ApiOperation<I, O>,
  options?: Options,
): { executeAsync: (params?: I) => Promise<O>; loading: boolean } {
  const { getIdTokenAsync } = useCourtAuth();
  const { openErrorModal, closeAllErrorModal } = useErrorModal();

  const { openRefreshTokenErrorModal } = useRefreshTokenErrorModal();

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = usePartnerNavigate();

  const executeAsync: (params?: I) => Promise<O> = useCallback(
    async (params?: I) => {
      try {
        setLoading(true);

        let axiosRequestConfig: AxiosRequestConfig = {
          baseURL: process.env.REACT_APP_PPOINT_BASEURL,
        };

        if (!options?.noAuthenticationRequired) {
          // 인증 필요
          const idToken = await getIdTokenAsync();
          log("executeAsync", "idToken", idToken);

          // refresh token 에러 테스트
          // throw new CourtAuthError("oauth2::access_denied", "LOCKED");

          axiosRequestConfig = {
            ...axiosRequestConfig,
            ...{
              headers: { Authorization: `Bearer ${idToken}` },
            },
          };
        }

        const axiosInstance = axios.create(axiosRequestConfig);

        return await apiOperation(axiosInstance, params);
      } catch (_error: any) {
        console.error("executeAsync error", _error);
        console.log(`params`, params);
        console.log("error::", { ..._error });

        // noHandleError 옵션이 없으면 에러를 api-operation에서 처리함
        if (!options?.noHandleError) {
          // 에러 핸들링
          if (_error instanceof CourtAuthError) {
            // court auth error
            const getIdTokenError = _error as CourtAuthError;
            if (getIdTokenError.code === "oauth2::invalid_token" || getIdTokenError.code === "oauth2::access_denied") {
              // refresh token 에러 모달
              openRefreshTokenErrorModal(getIdTokenError.code, getIdTokenError.message);
            }
          } else if (_error.response === undefined) {
            openErrorModal({ errorMessage: "잘못 된 요청이거나, 해당 페이지에 권한이 없습니다." });
          } else if (_error.response.data.meta.errorCode === "eCT604" || _error.response.data.meta.errorCode === "eCT609") {
            openErrorModal({ errorMessage: "잘못 된 요청이거나, 해당 페이지에 권한이 없습니다." });
            navigate("/court/error/page-not-allowed");
          } else if (_error.response.data.meta.errorCode === "eCT801") {
            openErrorModal({
              errorCode: _error?.response?.data?.meta?.errorCode, //
              errorMessage: "지금은 시스템에서 정기결제를 \n 진행 중입니다.",
              errorData: _error?.response?.data?.meta?.errorData,
              statusCode: _error?.response?.status,
            });
          } else if (_error.response.data.meta.errorCode === "EMAIL_ALREADY_IN_USE") {
            openErrorModal({
              errorMessage: "중복된 이메일입니다.",
            });
          } else {
            // 그외 에러
            let errorCode = "";
            let errorMessage = "";
            let errorData = "";
            let statusCode = 0;
            if (_error?.response?.data?.meta?.errorCode) {
              errorCode = _error?.response?.data?.meta?.errorCode;
            }
            if (_error?.response?.data?.meta?.errorMessage) {
              errorMessage = _error?.response?.data?.meta?.errorMessage;
            }
            if (!errorMessage) {
              errorMessage = _error?.message || "에러가 발생했습니다.";
            }
            if (_error?.response?.data?.meta?.errorData) {
              errorData = _error?.response?.data?.meta?.errorData;
            }
            if (_error?.response?.status) {
              statusCode = Number(_error?.response?.status);
            }
            // 에러 모달
            openErrorModal({ errorCode, errorMessage, errorData, statusCode });
          }
          return _error;
        }
        if (options?.anotherHandleError) {
          closeAllErrorModal();
          return _error;
        }

        const errorResponse: O = _error.response;
        return errorResponse;
        // noHandleError 옵션이 있으면 에러를 throw함
        // throw _error;
      } finally {
        setLoading(false);
      }
    },
    [apiOperation, getIdTokenAsync],
  );

  return { executeAsync, loading };
}
