import { useMemo } from "react";
import { BillingContractBillModel, ContractModel } from "src/api/contract/contract-types";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { BillItemByOrder, filterBillList, isBillPaySuccess } from "../../bill/contract-bill-types";

type Props = {
  contract?: ContractModel | null;
  billingContractBill?: BillingContractBillModel | null;
};
/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 화면 > 나의 납부 상태
 * (청구금액이 하나라도 유료 (0원 초과)일 경우만 노출)
 * (F/O 타입 이고, 계약체결 / 이용승인 / 이용중 / 이용완료 / 해지접수 / 해지완료 일 경우 노출)
 */
const MyPaymentStatus = ({ contract, billingContractBill }: Props) => {
  const navigate = usePartnerNavigate();
  // 모든 청구서의 청구금액이 0원인지 여부
  const isEveryBillAmountZero = useMemo(() => {
    return (billingContractBill?.billList || []).every((bill) => Number(bill?.originBill.totalAmount || 0) === 0);
  }, [billingContractBill]);

  // 회차별 청구 목록
  const billItemListByOrder: BillItemByOrder[] = useMemo(() => {
    // 전체 청구 목록
    const billList = filterBillList(billingContractBill?.billList);
    if (billList.length === 0) return [];

    // 회차별 청구 목록
    const _billItemListByOrder: BillItemByOrder[] = [];
    for (let i = 0; i < billList.length; i++) {
      const bill = billList[i];
      if (!_billItemListByOrder.find((_billItem) => _billItem.billOrder === bill.billOrder)) {
        const _billItemByOrder: BillItemByOrder = {
          billOrder: bill.billOrder!,
          bill: billList.find((_bill) => _bill.billOrder === bill.billOrder && !_bill.originBill.isOverdueBill)!, // 해당 회차의 원청구
          overdueBill: billList.find((_bill) => _bill.billOrder === bill.billOrder && _bill.originBill.isOverdueBill)!, // 해당 회차의 연체청구
        };
        _billItemListByOrder.push(_billItemByOrder);
      }
    }
    return _billItemListByOrder.sort((a, b) => b.billOrder - a.billOrder);
  }, [billingContractBill]);

  //
  // 나의 납부상태 미납 여부
  const isNonPayment: boolean = useMemo(() => {
    // 청구 내역이 없을때 납부
    if (billItemListByOrder.length === 0) return false;
    // 미납된 것들만 필터링
    const nonPayments = billItemListByOrder.filter((billItem) => {
      // 원청구는 납부

      if (isBillPaySuccess(billItem.bill?.billPayStatus)) {
        // 연체료 납부 여부
        if (billItem.bill.overduePayStatus === null) {
          return false;
        } else {
          return !isBillPaySuccess(billItem.bill.overduePayStatus);
        }

        // if (billItem?.overdueBill) {
        //   // 연체청구가 납부상태인지
        //   return !isBillPaySuccess(billItem.overdueBill?.billPayStatus);
        // } else {
        //   // 원청구 실시간 연체료 여부
        //   return Number(billItem.bill?.overdueAmount || 0) > 0;
        // }
      }
      // 원청구 미납
      return true;
    });
    return nonPayments.length > 0;
  }, [billItemListByOrder]);

  //

  const rentalTypeSchedule = useMemo(() => {
    if (contract && contract.scheduleList.length > 0) {
      const rentalSchedule = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
      if (rentalSchedule) {
        return rentalSchedule;
      }
    }
  }, [contract]);

  if (!contract || !contract?.contractStep) return null;

  if (
    !isEveryBillAmountZero &&
    contract?.spaceProductType !== "TIME_COURT" &&
    ["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE", "TERMINATE_RECEIVED", "TERMINATE_COMPLETE"].includes(
      contract?.contractStep || "",
    )
  ) {
    return (
      <section className="base-section-layout top-divider">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap mb30 pb0">
            {["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS"].includes(contract?.contractStep || "") && billItemListByOrder.length === 0 ? (
              // 계약체결, 이용승인, 이용중 이고 청구목록이 없을 경우 (1회차가 도래하지 않아)
              <>
                <div className="base-section-layout__title-wrap">
                  <h2>나의 납부 상태</h2>
                  <div className={`base-chip color-gray`}>
                    <span>대기</span>
                  </div>
                </div>
                <p className="pb10 font14 text-primary3 lh-20">
                  자세한 납부 정보는 <b>청구 내역</b>에서 확인해 주세요.
                </p>
              </>
            ) : (
              <>
                <div className="base-section-layout__title-wrap">
                  <h2>나의 납부 상태</h2>
                  <div className={`base-chip ${isNonPayment ? "color-red" : "color-gray"}`}>
                    <span>{isNonPayment ? "미납" : "납부완료"}</span>
                  </div>
                </div>
                {isNonPayment ? (
                  <p className="pb10 font14 text-primary3 lh-20">
                    장기 연체 시 이용제한 등의 조치가 이루어질 수 있습니다.
                    <br /> 자세한 납부 정보는 <b>청구 내역</b>에서 확인해 주세요.
                  </p>
                ) : (
                  <p className="pb10 font14 text-primary3 lh-20">
                    자세한 납부 정보는 <b>청구 내역</b>에서 확인해 주세요.
                  </p>
                )}
              </>
            )}

            <div className="pt20 d-flex flex-center">
              <button
                type="button"
                className="base-btn color-white size-small "
                onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}/paymentSchedule`)}
              >
                납부 일정
              </button>
              <button
                type="button"
                className="base-btn color-white size-small ml14"
                onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}/bills`)}
              >
                청구 내역
              </button>

              {/* 카드결제 방식일 때 결제내역 버튼 노출 */}
              {rentalTypeSchedule?.recentBill?.pgcode === "creditcard" && (
                <button
                  type="button"
                  className="base-btn color-white size-small ml14"
                  onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}/payments`)}
                >
                  결제 내역
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default MyPaymentStatus;
