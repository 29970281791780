import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPublicProductGuideAsync, getPublicProductGuidesAsync } from "src/api/guide/guide-api";
import { ProductGuideDetail } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { getPublicOrganizationAsync } from "src/api/organization/organization-api";
import { getExternalVisitorAsync } from "src/api/visitor/visitor-api";
import { Visitor } from "src/api/visitor/visitor-types";
import { ConfirmModal } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import CompanyLogo from "../components/CompanyLogo";
import MapGuide from "../components/MapGuide";
import ParkingGuide from "../components/ParkingGuide";
import WifiGuide from "../components/WifiGuide";
import { Modal } from "../visitor-types";
import usePostMessage from "src/pages/hooks/post-message";
import Header from "src/components/layout/Header";

// 방문자 에러 상태
type VisitorErrorState = {
  errorCode: string;
};

/**
 * 방문자 초대 상세 "미리보기" 웹뷰화면 (Taap에서 방문자초대 미리보기)
 * invitationKey = 초대자 회원번호(방문자초대 상세>신청계약번호>회원번호) + 방문신청번호(방문자초대 목록)
 */

const InvitationDetail = () => {
  const initializedRef = useRef(false);
  const { postMessageGoBackNavigation } = usePostMessage();
  const navigate = useNavigate();
  const params = useParams();
  // (무인증) 방문자 초대내역 정보 조회 api
  const { executeAsync: getVisitor } = useApiOperation(getExternalVisitorAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 회사 정보 조회 api
  const { executeAsync: getOrganization } = useApiOperation(getPublicOrganizationAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 공간상품 사용안내 목록조회 api
  const { executeAsync: getPublicProductGuides } = useApiOperation(getPublicProductGuidesAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // (무인증) 공간상품 사용안내 상세조회 (new) api
  const { executeAsync: getPublicProductGuide } = useApiOperation(getPublicProductGuideAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // 회사로고
  const [companyLogo, setCompanyLogo] = useState<string>("");

  // 방문자 초대 상세
  const [visitor, setVisitor] = useState<Visitor>();

  // 공간상품 사용안내 목록
  const [productGuides, setProductGuides] = useState<ProductGuideDetail[]>([]);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 방문자 에러 상태
  const [visitorErrorState, setVisitorErrorState] = useState<VisitorErrorState>();

  // 회사 정보 조회
  const fetchOrganization = useCallback(
    async (organizationId: number) => {
      try {
        const { data } = await getOrganization({ organizationId });
        if (data?.data?.mediaList && data?.data?.mediaList.length > 0) {
          const url = data.data.mediaList[0].url || "";
          if (url) {
            // 회사 로고
            setCompanyLogo(url);
          }
        }
      } catch (error: any) {
        // 회사 로고는 없으면 기본으로 적용하기 때문에 에러 핸들링 하지 않음
        console.error("fetchOrganization error", error.response);
      }
    },
    [getOrganization],
  );

  // (무인증) 공간상품 사용안내 목록조회 후 상세조회해서 목록 바인딩
  const fetchPublicProductGuides = useCallback(
    async (productId: number | string, buildingId: number | string) => {
      try {
        const { data } = await getPublicProductGuides({ productId, buildingId });

        if (data?.data?.content || [].length > 0) {
          // 상세 조회
          const promises = (data?.data?.content || []).map((productGuide) => getPublicProductGuide({ guideId: productGuide.id! }));
          const results = await Promise.all(promises);
          const _productGuides: ProductGuideDetail[] = results.map(({ data }) => data?.data?.content);
          setProductGuides(_productGuides);
        }
      } catch (error: any) {
        setAlertModal({ isOpen: true, message: "에러가 발생했습니다." });
      }
    },
    [getPublicProductGuides, getPublicProductGuide],
  );

  // 방문초대자 초대내역 정보 조회
  const fetchVisitor = useCallback(
    async (invitationKey: string) => {
      try {
        const { data } = await getVisitor({ invitationKey });
        const visitor: Visitor = data?.data;
        setVisitor(visitor);

        if (visitor.mbOrganizationId) {
          // 회사 정보 조회
          fetchOrganization(visitor.mbOrganizationId);
        }
        if (visitor.spaceProductId && visitor.buildingId) {
          // 공간상품 사용안내 목록조회 (무인증)
          fetchPublicProductGuides(visitor.spaceProductId, visitor.buildingId);
        }
      } catch (error: any) {
        if (error?.response) {
          // 방문자 에러 상태
          setVisitorErrorState({ errorCode: error?.response?.data?.meta?.errorCode });
        } else {
          setAlertModal({ isOpen: true, message: "에러가 발생했습니다." });
        }
      }

      if (!initializedRef.current) {
        initializedRef.current = true;
      }
    },
    [getVisitor, fetchOrganization, fetchPublicProductGuides],
  );

  // 방문일시 ex) 3월 21일 오후 2:00 - 오후 3:00
  const visitDateTime = useMemo(() => {
    if (!visitor) return "";
    let result = "";
    const { visitStartTime, visitEndTime } = visitor;
    const isSameDay = moment(visitStartTime).isSame(visitEndTime, "day");
    // 시작일시
    result += moment(visitStartTime).format("M월 D일");
    result += moment(visitStartTime).format(" A");
    result += moment(visitStartTime).format(" h:mm");
    result += " -";
    if (!isSameDay) {
      // 같은날이 아니면 종료일 추가
      result += moment(visitEndTime).format(" M월 D일");
    }
    // 종료시간
    result += moment(visitEndTime).format(" A");
    result += moment(visitEndTime).format(" h:mm");
    return result;
  }, [visitor]);

  useEffect(() => {
    if (params && params.invitationKey) {
      fetchVisitor(params.invitationKey);
    }
  }, []);

  if (!initializedRef.current) return null;

  // 방문자 에러 상태
  if (visitorErrorState) {
    if (visitorErrorState.errorCode === "eCT506") {
      // 방문 초대가 취소되었습니다. (방문자삭제여부: {0})
      return (
        <>
          <MetaTag title="방문자 이용 안내 | TaapSpace" />
          {window.ReactNativeWebView && <Header headerType="CLOSE" onClickCloseButton={() => postMessageGoBackNavigation()} />}
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>방문 초대가 취소되었습니다</p>
            </div>
          </div>
        </>
      );
    } else if (visitorErrorState.errorCode === "eCT505") {
      // 방문시간이 종료되었습니다. (방문종료일시: {0})
      return (
        <>
          <MetaTag title="방문자 이용 안내 | TaapSpace" />
          {window.ReactNativeWebView && <Header headerType="CLOSE" onClickCloseButton={() => postMessageGoBackNavigation()} />}
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                방문기간 종료로
                <br />
                더이상 유효하지 않은 페이지입니다
              </p>
            </div>
          </div>
        </>
      );
    } else if (visitorErrorState.errorCode === "eCT501") {
      // {0} 정보를 찾을 수 없거나 삭제되었습니다. (아이디: {1})
      return (
        <>
          <MetaTag title="방문자 이용 안내 | TaapSpace" />
          {window.ReactNativeWebView && <Header headerType="CLOSE" onClickCloseButton={() => postMessageGoBackNavigation()} />}
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                방문자 초대 정보를 찾을 수 없거나
                <br />
                삭제되었습니다
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <MetaTag title="방문자 이용 안내 | TaapSpace" />
          {window.ReactNativeWebView && <Header headerType="CLOSE" onClickCloseButton={() => postMessageGoBackNavigation()} />}
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                이 페이지가 맞나요? <br />
                정확한 주소인지 다시 한 번 확인해주세요
              </p>
            </div>
          </div>
        </>
      );
    }
  }
  return (
    <>
      <MetaTag title="방문자 이용 안내 | TaapSpace" />
      {window.ReactNativeWebView && <Header headerType="CLOSE" onClickCloseButton={() => postMessageGoBackNavigation()} />}
      <div className="visitor">
        <CompanyLogo />
        <article className="visitor__intro">
          <h1>
            {visitor?.contractName ? visitor.contractName : visitor?.spaceProductName}
            <br />
            방문을 환영합니다
          </h1>
          <p>방문일시: {visitDateTime}</p>
        </article>
        <article className="visitor__qr">
          <div className="qr-container pt40">
            <div className="qr-container__container">
              <div className="frame-overlay">
                <div className="frame-element top-left"></div>
                <div className="frame-element top-right"></div>
                <div className="frame-element bottom-left"></div>
                <div className="frame-element bottom-right"></div>
              </div>
              <div className="qr-container__wrap">
                <div className="waiting-qr invitation">
                  <p>방문자마다 다른 QR코드가 안내됩니다.</p>
                </div>
              </div>
            </div>
            <>
              <p className="main-text">출입 단말기에 QR코드를 Taap 하세요.</p>
              <p className="sub-text">방문 일시 30분전 부터 입장이 가능합니다.</p>
            </>
          </div>
        </article>
        <article className="visitor__info pt40">
          {/* 오시는 길 */}
          <MapGuide visitor={visitor} />
          {/* 주차 안내 */}
          <ParkingGuide visitor={visitor} fetchVisitor={fetchVisitor} productGuides={productGuides} isPreview={true} />
          {/* 와이파이 이용안내 */}
          <WifiGuide productGuides={productGuides} />
        </article>
      </div>

      <ConfirmModal isOpen={alertModal.isOpen} btnRightTitle={"확인"} onClick={() => setAlertModal({ isOpen: false })}>
        {alertModal.message || ""}
      </ConfirmModal>
    </>
  );
};
export default InvitationDetail;
