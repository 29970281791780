import { atom } from "recoil";

export enum LogLevel {
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
  DEBUG = "debug",
}

export type LoggerState = {
  logLevel?: LogLevel;
};

const key = "loggerState";

export const getDefaultLogLevel = () => {
  const env = process.env.REACT_APP_ENV;
  console.debug('env', env);
  if (env === 'prod') {
    return LogLevel.ERROR;
  }
  else if (env === 'stg') {
    return LogLevel.WARN;
  }
  else if (env === 'dev') {
    return LogLevel.INFO;
  }
  return LogLevel.DEBUG;
}

/**
 * loggerState 설정
 */
export const loggerState = atom<LoggerState>({
  key: key,
  default: {
  },
  effects: [
    ({ setSelf, onSet }) => {
      const loggerInfo = localStorage.getItem(key);

      if (loggerInfo) {
        setSelf(JSON.parse(loggerInfo));
      }

      onSet((newValue, _, isReset) => {
        if (isReset) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    },
  ],
});
