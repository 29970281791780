import moment from "moment";
import { CreditCardDetailsProps } from "../types";
import { PriceRow } from "./PriceRow";
import { getPaymentReceiptAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";

export const CreditCardDetails = ({ payment, receiptBundle }: CreditCardDetailsProps) => {
  const { executeAsync: getPaymentReceipt } = useApiOperation(getPaymentReceiptAsync);
  // 거래내역 확인서
  const fetchPaymentReciptUrl = async (payment: any) => {
    const { data } = await getPaymentReceipt({ id: String(payment.paymentId) });
    const url = data.data.receipt_url;

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openInAppBrowser",
          data: {
            event: url,
          },
        }),
      );
    } else {
      window.open(url);
      // window.location.href = url;
    }
  };
  return (
    <>
      <PriceRow label="상품금액" value={payment.totalAmount - payment.taxAmount} />
      <PriceRow label="부가세" value={payment.taxAmount} />
      <PriceRow label="총 결제금액" value={payment.totalAmount} isTotal />
      {/* //horizontal-divider */}

      {payment.cardInfo && (
        <>
          <div className="horizontal-divider" />
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">결제수단</p>
            <p className="base-section-layout-value">카드결제</p>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3"></p>
            <p className="base-section-layout-value text-primary3 font14 font-weight-400">
              결제 완료 시 영수증은 자동 발급되며, 승인 취소와
              <br />
              환불까지 영업일 기준으로 5일 정도 소요됩니다.
            </p>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">카드번호</p>
            <p className="base-section-layout-value">{payment.cardInfo}</p>
          </div>
        </>
      )}
      {payment.paymentCancel ? (
        <>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">취소일시</p>
            <p className="base-section-layout-value">{moment(payment.paymentCancel.cancelDate).format("YYYY-MM-DD HH:mm")}</p>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">취소금액</p>
            <p className="base-section-layout-value">{payment.paymentCancel.cancelAmount} 원</p>
          </div>
        </>
      ) : (
        receiptBundle?.lastTrDateTime && (
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">결제일시</p>
            <p className="base-section-layout-value">{moment(receiptBundle.lastTrDateTime).format("YYYY-MM-DD HH:mm")}</p>
          </div>
        )
      )}
      {receiptBundle!.lastTrDateTime! && (
        <button
          className="mt20 base-btn color-white card_payment_receipt"
          onClick={async () => {
            fetchPaymentReciptUrl(payment);
          }}
        >
          카드 영수증 보기
        </button>
      )}
    </>
  );
};
