import { useNavigate, useLocation } from "react-router-dom";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import Header from "src/components/layout/Header";

const ServiceTypeGuidelines = () => {
  const navigate = usePartnerNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const guideInfo = JSON.parse(decodeURIComponent(searchParams.get("data") || "{}"));
  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      {guideInfo && guideInfo.initValue !== undefined && guideInfo.initValue !== "" && (
        <>
          <Header
            headerType="BACK"
            title="유의사항"
            onClickBackButton={() => {
              onClose()!;
            }}
          />
          <section className="base-section-layout w-100">
            <div className="base-section-layout__container">
              <div className="base-section-layout__wrap">
                <div className="base-section-layout__title-wrap">
                  <h2>{"유의사항"}</h2>
                </div>
                <div>
                  <div>
                    {guideInfo.initValue.split("\n").map((line: any, idx: number) => {
                      return (
                        <span key={idx}>
                          <p className="font14">{line}</p>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ServiceTypeGuidelines;
