import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BaseRadio } from "src/components/layout/BaseRadio";
import TaxInvoiceInfo from "./switchInvoice/TaxInvoiceInfo";
import { YmdFormat } from "src/utils/common-util";
import moment from "moment";
import _ from "lodash";
import { CashReceiptForm } from "./paymentDetails/CashReceiptForm";

type Props = {
  currentPgCode: string;
  recentBill: any;
  newPgCode: Dispatch<SetStateAction<string>>;
  documentProofRequest: any;
  setDocumentProofRequest: any;
  proofError: any;
  setProofError: any;
};
const ServiceTypePaymentMethodSelector = ({
  recentBill,
  currentPgCode,
  newPgCode,
  documentProofRequest,
  setDocumentProofRequest,
  proofError,
  setProofError,
}: Props) => {
  const [pgCode, setPgCode] = useState<string>(currentPgCode);
  const [tempProof, setTempProof] = useState(documentProofRequest);
  const [invoice, setInvoice] = useState<string>("");
  useEffect(() => {
    if (documentProofRequest.expenseProofType !== null) {
      setInvoice(documentProofRequest.expenseProofType);
    }
  }, [documentProofRequest]);
  return (
    <section className="base-section-layout top-divider">
      <div className="base-section-layout__container">
        <div className="pb20">
          <div className="base-section-layout__title-wrap">
            <h2 className="mypage_required">결제 수단</h2>
          </div>
          <p className="font14 text-primary3 lh-20">
            <span className="text-red">{moment(recentBill?.billDueDate).format(YmdFormat.YYYY_MM_DD_HH_MM) + " 까지 "}</span>
            결제를 완료해 주세요. <br />
            결제시간 경과 시 예약이 취소됩니다.
          </p>
        </div>
        <div className="py20 border-top">
          <div className="radio-button-wrap">
            <BaseRadio
              id="creditCard"
              name="pgCode"
              value={"creditcard"}
              label="신용카드"
              checked={pgCode === "creditcard"}
              onChange={(value: boolean) => {
                setPgCode("creditcard");
                newPgCode("creditcard");
                if (documentProofRequest.contractId !== null) {
                  const temp = _.cloneDeep(documentProofRequest);
                  setDocumentProofRequest(temp);
                }
              }}
            />
            <BaseRadio
              id="virtualAccount"
              name="pgCode"
              value={"virtualaccount"}
              label="가상계좌"
              checked={pgCode === "virtualaccount"}
              onChange={(value: boolean) => {
                setPgCode("virtualaccount");
                newPgCode("virtualaccount");
                const temp = _.cloneDeep(documentProofRequest);
                temp.expenseProofType = "CASHBILL";
                temp.identityType = null;
                setDocumentProofRequest(temp);
                console.log("temp mother", temp);
              }}
            />
          </div>
          {pgCode === "virtualaccount" && (
            <>
              <div className="tab-radio">
                <div className="tab-radio__wrap">
                  <input
                    id="CASHBILL"
                    type="radio"
                    name="invoice"
                    value={"CASHBILL"}
                    checked={invoice === "CASHBILL"}
                    onChange={() => {
                      const temp = _.cloneDeep(tempProof);
                      temp.expenseProofType = "CASHBILL";
                      temp.identityType = "MOBILE_NUM";
                      temp.tradeUsage = "INCOME";
                      if (temp.contractId === null || Number(documentProofRequest.contractId) === 0) {
                        temp.identityNum = null;
                      }

                      temp.mediaList = [];
                      setDocumentProofRequest(temp);
                      setInvoice("CASHBILL");
                    }}
                  />
                  <label htmlFor="CASHBILL" className={`tab-radio__label ${invoice === "CASHBILL" ? "--active" : ""}`}>
                    현금영수증
                  </label>
                  <input
                    id="TAX_INVOICE"
                    name="invoice"
                    className="tab-radio"
                    type="radio"
                    value={"TAX_INVOICE"}
                    checked={invoice === "TAX_INVOICE"}
                    onChange={() => {
                      setInvoice("TAX_INVOICE");
                      const temp = _.cloneDeep(documentProofRequest);
                      temp.expenseProofType = "TAX_INVOICE";
                      temp.identityNum = "";
                      temp.identityType = "CORP_NUM";
                      setDocumentProofRequest(temp);
                      console.log("TAX_INVOICE", temp);
                    }}
                  />
                  <label htmlFor="TAX_INVOICE" className={`tab-radio__label ${invoice === "TAX_INVOICE" ? "--active exist-radius" : ""}`}>
                    세금계산서
                  </label>
                </div>
              </div>
              <div className="base-section-layout-line flex-row flex-center-start s2-payment-info">
                {invoice === "TAX_INVOICE" && (
                  <TaxInvoiceInfo
                    mode="edit"
                    documentProofRequest={documentProofRequest}
                    setDocumentProofRequest={setDocumentProofRequest}
                    proofError={proofError}
                    setProofError={setProofError}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceTypePaymentMethodSelector;
