import { getDefaultLogLevel, LogLevel } from "src/recoil/logger/atom";

/**
 * console 함수 정보
 */
const loggerInfo = {
  error: console.error,
  warn: console.warn,
  info: console.info,
  log: console.log,
  debug: console.debug,
};

/**
 * 로그 레벨 설정
 */
export const setLogLevel = (_level?: LogLevel) => {
  let level = _level || getDefaultLogLevel();

  switch (level) {
    case LogLevel.ERROR: {
      window.console.warn = () => {};
      window.console.info = () => {};
      window.console.log = () => {};
      window.console.debug = () => {};
      break;
    }
    case LogLevel.WARN: {
      window.console.warn = loggerInfo.warn;
      window.console.info = () => {};
      window.console.log = () => {};
      window.console.debug = () => {};
      break;
    }
    case LogLevel.INFO: {
      window.console.warn = loggerInfo.warn;
      window.console.info = loggerInfo.info;
      window.console.log = loggerInfo.log;
      window.console.debug = () => {};
      break;
    }
    case LogLevel.DEBUG: {
      window.console.warn = loggerInfo.warn;
      window.console.info = loggerInfo.info;
      window.console.log = loggerInfo.log;
      window.console.debug = loggerInfo.debug;
      break;
    }
  }
};

export default function log(mark: string, ...args: any[]) {
  console.log(`${new Date().toJSON()} - [${mark}]`, ...args);
}
