import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { getPublicProductAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { findPrimaryBuilding, officeFirstMediaSorting } from "../product-types";
import BuildingsInfoArea from "./components/BuildingsInfoArea";
import CommonFacilityInfoArea from "./components/CommonFacilityInfoArea";
import MainArea from "./components/MainArea";
import ProductInfoArea from "./components/ProductInfoArea";
import Link from "src/components/PartnerLink";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
/**
 * 공간상품 상세화면
 */

const ProductDetail = () => {
  const navigate = usePartnerNavigate();
  const { productId } = useParams();
  const location = useLocation();
  SwiperCore.use([Parallax, Navigation, Pagination]);
  const [product, setProduct] = useState<ProductModel | null>(null);
  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);
  // (무인증) 공간상품 정보 조회 api
  const { executeAsync: getPublicProduct } = useApiOperation(getPublicProductAsync, { noAuthenticationRequired: true });
  // (무인증) 공통 미디어파일 목록 api
  const { executeAsync: getPublicMediaFiles } = useApiOperation(getPublicMediaFilesAsync, { noAuthenticationRequired: true, noHandleError: true });

  // 무인증 미디어 파일 호출
  const fetchPublicMediaFiles = useCallback(
    async (id: number[]) => {
      if (id.length > 0) {
        const { data } = await getPublicMediaFiles({
          serviceId: id.join(","),
          mediaServiceTypes: [
            "MEDIA_PRODUCT_OFFICE",
            "MEDIA_PRODUCT_FLOOR_PLAN",
            "MEDIA_PRODUCT_MEETING_ROOM",
            "MEDIA_PRODUCT_DESK",
            "MEDIA_PRODUCT_REFRESH_ROOM",
            "MEDIA_PRODUCT_TOILET",
            "MEDIA_PRODUCT_PARKING_LOT",
            "MEDIA_PRODUCT_FACILITY",
            "MEDIA_PRODUCT_ETC",
          ], //상품 내 이미지 카테고리
        });
        if (data?.data?.content && (data?.data?.content || []).length > 0) {
          return officeFirstMediaSorting(data?.data?.content);
        }
      }
    },
    [getPublicMediaFiles],
  );
  useEffect(() => {
    const fetchProductDetail = async (id: string | number) => {
      const { data, status } = await getPublicProduct({ productId: id });
      if (status >= 200 && status <= 299) {
        setProduct(data.data.content);
      }
      const _mediaList = await fetchPublicMediaFiles([Number(id)]);
      setMediaList(_mediaList || []);
    };
    if (productId) {
      fetchProductDetail(productId);
    }
  }, [productId, getPublicProduct, fetchPublicMediaFiles]);

  return (
    <>
      <MetaTag title="공간상품 | TaapSpace" />
      <Header
        headerType="BACK"
        title={product?.productName ? `${product?.productName}` : ""}
        rightArea={<Link to="/court/main" className="home"></Link>}
      />
      <div className="product-detail">
        <div className={`slider list ${!mediaList || mediaList?.length === 0 ? "noImage" : ""}`}>
          <Swiper
            pagination={{
              type: "fraction",
            }}
            parallax={true}
            className="detail-product-swiper"
          >
            {mediaList.map((productImage: any, index: number) => {
              return (
                <SwiperSlide key={index.toString()}>
                  <div
                    className="slider-bg"
                    style={{
                      backgroundImage: `url(${productImage.url})`,
                    }}
                    data-swiper-parallax={"23%"}
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <article className="product-info">
          {/* 메인정보 */}

          <MainArea product={product} />

          {product?.productType === "FULL_COURT" && (
            <>
              {/* 상품정보 */}
              <ProductInfoArea product={product} primaryBuilding={product?.buildingList ? findPrimaryBuilding(product?.buildingList) : {}} />
              {/* 건물 상세정보 */}
              <BuildingsInfoArea product={product} />
              {/* 공용공간 */}
              <CommonFacilityInfoArea product={product} />
            </>
          )}
          {product?.productType === "TIME_COURT" && (
            <>
              {/* 공용공간 */}
              <CommonFacilityInfoArea product={product} />
              {/* 상품정보 */}
              <ProductInfoArea product={product} primaryBuilding={product?.buildingList ? findPrimaryBuilding(product?.buildingList) : {}} />
              {/* 건물 상세정보 */}
              <BuildingsInfoArea product={product} />
            </>
          )}
        </article>
        {product?.productType === "FULL_COURT" && (
          <div className="base-floating-btn-wrap">
            <button
              className="base-btn"
              onClick={() =>
                // setIsCSModalOpen(true)
                {
                  const prevURL = process.env.REACT_APP_PPOINT_BASEURL + location.pathname;
                  const state = {
                    prevURL,
                  };
                  navigate(`/court/product/${productId}/inquiry`, { state });
                }
              }
            >
              문의하기
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default ProductDetail;
