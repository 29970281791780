import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getFacilityReservationTime } from "src/api/facility/facility-api";
import { ContractReservationResponse, ContractReservationUnit, FrontContractFacilityData } from "src/api/facility/facility-type";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { makeTimeString } from "src/utils/common-util";

const ReservationFacility = [
  {
    label: "회의실",
    value: "MEETING_ROOM",
  },
  {
    label: "좌석",
    value: "DESK",
  },
  {
    label: "편의시설",
    value: "REFRESH_ROOM",
  },
];

const FacilityTime = () => {
  const { goBack } = useNavigateGoBack();
  const { contractId } = useParams();

  const [daily, setDaily] = useState<ContractReservationResponse>();
  const [monthly, setMonthly] = useState<ContractReservationResponse>();

  const reservationTimeData: any = useMemo(() => {
    if (daily && monthly) {
      let componentsArray = null;
      if (monthly?.components !== null) {
        componentsArray = monthly?.components.map((monthlyItem) => {
          daily?.components.forEach((dailyItem) => {
            if (monthlyItem.facilityType === dailyItem.facilityType) {
              monthlyItem = { ...monthlyItem, monthlyLimit: monthlyItem.limit, dailyLimit: dailyItem.limit, monthlyUsage: monthlyItem.usage } as any;
            }
          });
          delete monthlyItem.limit;
          // delete monthlyItem.usage;
          return monthlyItem;
        });
      }

      return { ...monthly, components: componentsArray };
    }
  }, [daily, monthly]);

  const { executeAsync: getReservationTime } = useApiOperation(getFacilityReservationTime);

  useEffect(() => {
    const units: ContractReservationUnit[] = ["MONTHLY", "DAILY"];
    const fetchReservationTime = async (unit: ContractReservationUnit) => {
      const { data, status } = await getReservationTime({ contractId: String(contractId), unit });

      if (status >= 200 && status <= 299) {
        if (unit === "DAILY") {
          setDaily(data.data.status);
        } else {
          setMonthly(data.data.status);
        }
      }
    };

    units.forEach((unit: ContractReservationUnit) => {
      fetchReservationTime(unit);
    });
  }, [contractId, getReservationTime]);

  const onChangeTimeValue = (value: string, type: "monthlyUsage" | "monthlyLimit" | "dailyLimit") => {
    let timeLimit = null;

    const facilityData = reservationTimeData?.components?.find((item: FrontContractFacilityData) => item.facilityType === value);
    switch (type) {
      case "monthlyUsage":
        timeLimit = facilityData?.monthlyUsage;
        break;
      case "monthlyLimit":
        timeLimit = facilityData?.monthlyLimit;
        break;
      case "dailyLimit":
        timeLimit = facilityData?.dailyLimit;
        break;
      default:
        break;
    }

    // return timeLimit === null ? "-" : timeLimit === 0 ? 0 : makeTimeString(timeLimit);
    return timeLimit === null ? "무제한" : timeLimit === undefined ? "-" : timeLimit === 0 ? "0 시간" : makeTimeString(timeLimit);
  };

  return (
    <div>
      {reservationTimeData && (
        <>
          <MetaTag title="마이페이지 | TaapSpace" />
          <Header
            headerType="BACK"
            title="공용공간 이용시간"
            onClickBackButton={() => {
              goBack();
            }}
          />
          <article className="facility-header ">
            <section className="content-layout">
              <div className="facility-title">
                <p>이용기간</p>
                <div>
                  <span className=" font-weight-400 font15">
                    {monthly?.start !== null
                      ? `${moment(monthly?.start).format("YYYY.MM.DD")} ~ ${moment(monthly?.end).subtract(1, "days").format("MM.DD")}`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="facility-description">
                <p>{monthly?.start ? "매월 1일 초기화 됩니다." : ""}</p>
              </div>
            </section>
          </article>
          <article className=" top-divider">
            <section className="content-layout">
              <div className="facility-title mb20">
                <p>이용시간</p>
              </div>

              {ReservationFacility.map((facility, idx: number) => (
                <div key={facility.value} className={`${idx === 1 && "divider-top-bottom"}`}>
                  <p className="font15 font-weight-600 mb10">{facility.label}</p>
                  <div className="facility-content">
                    <div className="mb4">
                      <span className="mr20 text-primary3">월 사용</span>
                      <span>{onChangeTimeValue(facility.value, "monthlyUsage")}</span>
                    </div>
                    {/* <div className="mb4">
                      <span className="mr20 text-primary3">월 한도</span>
                      <span>{onChangeTimeValue(facility.value, "monthlyLimit")}</span>
                    </div>
                    <div>
                      <span className="mr20 text-primary3">일 한도</span>
                      <span>{onChangeTimeValue(facility.value, "dailyLimit")}</span>
                    </div> */}
                  </div>
                </div>
              ))}
            </section>
          </article>
        </>
      )}
    </div>
  );
};

export default FacilityTime;
