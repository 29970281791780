import { BaseSelect, BaseTextInput } from "src/components";
import { CashReceiptFormProps, DocumentProofRequest } from "../types";
import { onlyNumber, validatePhoneNumberBoolean } from "src/utils/common-util";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getExpenseProofAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { useParams } from "react-router-dom";

export const CashReceiptForm = ({ handleClickExpenseProofCashBill }: CashReceiptFormProps) => {
  const [cashErrorTextFlag1, setCashErrorTextFlag1] = useState(false);
  const [cashErrorTextFlag2, setCashErrorTextFlag2] = useState(false);
  const [cashErrorTextFlag3, setCashErrorTextFlag3] = useState(false);
  const [cashErrorTextFlag4, setCashErrorTextFlag4] = useState(false);
  const { contractId } = useParams();
  const [documentProofRequest, setDocumentProofRequest] = useState<DocumentProofRequest>({
    identityNum: "",
    mediaList: [],
    taxInvoiceEmail: "",
    taxInvoicePhone: "",
    taxInvoiceDate: null,
    identityType: "",
    tradeUsage: "",
    contractId: Number(contractId),
    cmdType: "",
    expenseProofType: "",
  });
  // 지출 증빙 자료 조회
  const { executeAsync: getExpenseProof } = useApiOperation(getExpenseProofAsync);

  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data: data2 } = await getExpenseProof({ contractId });

      if (data2.data.expenseProofRequest.mediaList !== null) {
        data2.data.expenseProofRequest.mediaList.forEach((dt: any) => {
          dt["cmdType"] = "U";
        });
        setDocumentProofRequest(data2.data.expenseProofRequest);
      }
    },
    [getExpenseProof],
  );

  useEffect(() => {
    if (contractId) {
      fetchContract(contractId);
    }
  }, [fetchContract, contractId]);

  const valueMaker = (documentProofRequest: any) => {
    console.log("documentProofRequest", documentProofRequest);
    if (documentProofRequest.identityType.includes("CARD_NUM")) {
      if (documentProofRequest.tradeUsage === "INCOME") {
        return "I_CARD_NUM";
      }
      if (documentProofRequest.tradeUsage === "EXPENSE") {
        return "E_CARD_NUM";
      }
    } else {
      return documentProofRequest.identityType;
    }
  };
  return (
    <div className="base-layout-contents">
      <div className="contract-page h-100">
        <div className="container">
          <BaseSelect
            className="identity-type"
            wrapClassName="text-left"
            value={valueMaker(documentProofRequest)}
            options={[
              { label: "개인소득공제(휴대폰)", value: "MOBILE_NUM", type: "INCOME" },
              { label: "개인소득공제(현금영수증카드)", value: "I_CARD_NUM", type: "INCOME" },
              { label: "사업자증빙용(사업자번호)", value: "CORP_NUM", type: "EXPENSE" },
              { label: "사업자증빙용(현금영수증카드) ", value: "E_CARD_NUM", type: "EXPENSE" },
            ]}
            getObj={true}
            onChange={(option: any) => {
              const temp: DocumentProofRequest = _.cloneDeep(documentProofRequest!);
              if (option.value.includes("CARD_NUM")) {
                temp.identityType = "CARD_NUM";
              } else {
                temp.identityType = option.value;
              }

              temp.tradeUsage = option.type;
              temp.identityNum = "";
              setDocumentProofRequest(temp as DocumentProofRequest);
            }}
            placeholder="신청 유형을 입력해 주세요 (필수)"
          />
        </div>
        <div className="container">
          <BaseTextInput
            value={documentProofRequest?.identityNum}
            onChange={(value: string | undefined) => {
              const temp = _.cloneDeep(documentProofRequest!);
              temp.identityNum = onlyNumber(String(value));
              setDocumentProofRequest(temp);
              if (value) {
                setCashErrorTextFlag1(false);
                setCashErrorTextFlag2(false);
                setCashErrorTextFlag3(false);
                setCashErrorTextFlag4(false);
              }
            }}
            onBlur={() => {
              const temp = _.cloneDeep(documentProofRequest!);
              if (temp.identityNum === "") {
                setCashErrorTextFlag1(false);
                setCashErrorTextFlag2(false);
                setCashErrorTextFlag3(false);
                setCashErrorTextFlag4(true);
              }
              if (temp.identityType === "MOBILE_NUM" && !validatePhoneNumberBoolean(temp.identityNum)) {
                setCashErrorTextFlag1(true);
                setCashErrorTextFlag2(false);
                setCashErrorTextFlag3(false);
                setCashErrorTextFlag4(false);
              }
              if (temp.identityType?.includes("CARD_NUM") && (temp.identityNum.length < 13 || temp.identityNum.length > 19)) {
                setCashErrorTextFlag1(false);
                setCashErrorTextFlag2(true);
                setCashErrorTextFlag3(false);
                setCashErrorTextFlag4(false);
              }
              if (temp.identityType === "CORP_NUM" && temp.identityNum.length !== 10) {
                setCashErrorTextFlag1(false);
                setCashErrorTextFlag2(false);
                setCashErrorTextFlag3(true);
                setCashErrorTextFlag4(true);
              }
              setDocumentProofRequest(temp);
            }}
            placeholder="숫자만 입력해 주세요 (필수)"
          />
          {cashErrorTextFlag1 && <p className="mt5 font12 text-red">휴대폰 번호 형식이 아닙니다.</p>}
          {cashErrorTextFlag2 && <p className="mt5 font12 text-red">카드번호 형식이 아닙니다.</p>}
          {cashErrorTextFlag3 && <p className="mt5 font12 text-red">사업자번호 형식이 아닙니다.</p>}
        </div>
      </div>
      <div className="base-floating-btn-wrap px0">
        <div className="container">
          <button
            type="button"
            className="base-btn primary-btn"
            disabled={
              documentProofRequest!.identityType === "" ||
              documentProofRequest!.identityType === null ||
              documentProofRequest!.identityNum === null ||
              documentProofRequest!.identityNum === "" ||
              cashErrorTextFlag1 ||
              cashErrorTextFlag2 ||
              cashErrorTextFlag3 ||
              cashErrorTextFlag4
            }
            onClick={() => handleClickExpenseProofCashBill(documentProofRequest!)}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
