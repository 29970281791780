import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBuildingsDetailsAsync, getMediasAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { MediaFile } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import BaseShareButton from "src/components/BaseShareButton";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { mediaSorting } from "src/pages/product/product-types";
import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BuildingPostDetail from "../components/BuildingPostDetail";
import MapView from "src/pages/components/MapView";
import Link from "src/components/PartnerLink";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
/**
 * 공간상품 상세화면
 */
const s3url = process.env.REACT_APP_S3_CONTENT_BASEURL;

const BuildingDetail = () => {
  const navigate = usePartnerNavigate();
  const { executeAsync: getPublicBuildingsDetail } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true });
  const { executeAsync: getMedias } = useApiOperation(getMediasAsync, { noAuthenticationRequired: true });

  const { buildingId } = useParams();

  // 건물 상세
  const [building, setBuilding] = useState<(BuildingModel & { media: MediaFile[] }) | null>(null);

  SwiperCore.use([Parallax, Navigation, Pagination]);

  useEffect(() => {
    const fetchApi = async (buildingId: string) => {
      const { data: buildingDetailData, status } = await getPublicBuildingsDetail({ buildingIds: [buildingId] });
      const { data: buildingMediaData } = await getMedias({ serviceId: buildingId, mediaServiceType: "MEDIA_BUILDING" });
      if (status >= 200 && status <= 299) {
        const buildingData = buildingDetailData.data.content[0].building;
        const sortedMedia = mediaSorting(buildingMediaData.data.content);
        setBuilding({ ...buildingData, media: sortedMedia });
      }
    };

    if (buildingId) {
      fetchApi(buildingId);
    }
  }, [getPublicBuildingsDetail]);
  return (
    <>
      <MetaTag title="건물상세 | TaapSpace" />
      <Header
        headerType="BACK"
        title={building?.buildingName ? `${building?.buildingName}` : ""}
        rightArea={<Link to="/court/main" className="home"></Link>}
      />

      <div className="building-detail">
        <section className={`slider ${building?.media.length === 0 ? "noImage" : ""}`}>
          <Swiper
            pagination={{
              type: "fraction",
            }}
            parallax={true}
            className="detail-product-swiper"
          >
            {building?.media?.map((buildingMedia: MediaFile, index: number) => {
              return (
                <SwiperSlide key={index.toString()}>
                  <div
                    className="slider-bg"
                    style={{
                      backgroundImage: `url(${buildingMedia.url})`,
                    }}
                    data-swiper-parallax={"23%"}
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
        <article className="building-info">
          {/* 메인정보 */}
          <section className="building-info__main bottom-divider">
            <div className="main-wrap">
              <h2 className="main-title">{building?.buildingName}</h2>
              <BaseShareButton />
            </div>
            <p className="description">{building?.feature}</p>
          </section>
          {/* 공용공간의 컴포넌트를 재사용하기위해 */}
          <div className="facility-detail">
            <article className="facility-info">
              <section className="facility-info">
                {building && (
                  <section className="facility-info__facility-info top-divider ">
                    <BuildingPostDetail building={building} />
                    <div className="horizontal-divider"></div>
                    <MapView building={building} />
                  </section>
                  // <>
                  //   <BuildingPostDetail building={building} />
                  //   <section className="facility-info__facility-info pt0 ">
                  //     <div className="horizontal-divider mt0"></div>
                  //     <MapView building={building} />
                  //   </section>
                  // </>
                )}
              </section>
            </article>
          </div>
        </article>
      </div>
    </>
  );
};
export default BuildingDetail;
