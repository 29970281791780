import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { getSmsVerificationsAsync, patchMemberPhoneNumberAsync, postSmsVerificationsAsync } from "src/api/member/member-api";
import { BaseButton, BaseTextInputWithButton, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";
import { convertKoreanPhoneNumber, validatePhoneNumber } from "src/utils/common-util";
import SmsCodeInput from "./components/SmsCodeInput";
import Timer from "./components/Timer";

type FormData = {
  phoneNumber: string;
  state?: string;
  expiresAt?: string;
  smsCode: string;
};

type Modal = {
  isOpen: boolean;
  message?: string;
  subMessage?: string;
};

/**
 * 마이페이지 메인 > 프로필 편집 > 휴대폰 변경 화면
 */
const PhoneEdit = () => {
  const navigate = usePartnerNavigate();

  // 로그인한 회원 정보
  const { member } = useMemberInfoState();

  // SMS 인증 코드 발송 api
  const { executeAsync: postSmsVerifications, loading: postSmsVerificationsLoading } = useApiOperation(postSmsVerificationsAsync);

  // SMS 인증 코드 검증 조회 api
  const { executeAsync: getSmsVerifications, loading: getSmsVerificationsLoading } = useApiOperation(getSmsVerificationsAsync);

  // 휴대폰 번호 변경 처리 api
  const { executeAsync: patchMemberPhoneNumber, loading: patchMemberPhoneNumberLoading } = useApiOperation(patchMemberPhoneNumberAsync);

  // SMS 인증 코드 발송 여부
  const [isCompletedSendSms, setIsCompletedSendSms] = useState<boolean>(false);

  // SMS 인증 코드 인증 여부
  const [isVerificationsSmsCode, setIsVerificationsSmsCode] = useState<boolean>(false);

  // 휴대폰 번호 변경 완료 여부
  const [isChangedPhoneNumber, setIsChangedPhoneNumber] = useState<boolean>(false);

  // 인증번호 입력 타이머 분
  const [mm, setMM] = useState<number>();
  // 인증번호 입력 타이머 초
  const [ss, setSS] = useState<number>();

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const defaultValues = useMemo(() => {
    return {
      phoneNumber: "",
      smsCode: "",
    };
  }, []);

  const { handleSubmit, register, control, setValue, getValues } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    register("phoneNumber", {
      validate: {
        validatePhoneNumber: (phoneNumber?: string) => {
          return validatePhoneNumber(phoneNumber);
        },
      },
    });
  }, [register]);

  // 인증번호 관련 데이터 reset
  const resetSmsCode = useCallback(() => {
    setIsCompletedSendSms(false);
    setMM(undefined);
    setSS(undefined);
    setValue("smsCode", "");
    setValue("expiresAt", "");
  }, [setValue]);

  // SMS 인증 코드 발송
  const sendSmsVerifications = useCallback(
    async (phoneNumber: string, state?: string) => {
      setMM(undefined);
      setSS(undefined);

      const { data } = await postSmsVerifications({
        phoneNumber,
        state,
      });
      if (data?.data?.smsVerification) {
        // 인증코드 발송 여부
        setIsCompletedSendSms(true);

        if (data.data.smsVerification.state) {
          setValue("state", data.data.smsVerification.state);
          setValue("expiresAt", data.data.smsVerification.expiresAt);

          const diff = moment(data.data.smsVerification.expiresAt).diff(moment());
          const duration = moment.duration(diff);
          setMM(duration.minutes());
          setSS(duration.seconds());
        }
        setAlertModal({ isOpen: true, message: "휴대폰 인증 번호를 전송했어요." });
      }
    },
    [postSmsVerifications, setValue],
  );

  // SMS 인증 코드 검증
  const checkSmsVerifications = useCallback(
    async (smsCode: string) => {
      const state = getValues("state");
      if (!state) throw Error("state is not found");

      const phoneNumber = getValues("phoneNumber");
      const { data } = await getSmsVerifications({
        state,
        phoneNumber,
        smsCode,
      });
      if (data?.data?.smsCodeVerification) {
        if (data?.data?.smsCodeVerification?.verified) {
          setIsVerificationsSmsCode(true);
        } else {
          setAlertModal({ isOpen: true, message: "올바르지 않은 인증번호입니다." });
        }
      }
    },
    [getSmsVerifications, getValues],
  );

  // 휴대폰 번호 변경
  const changePhoneNumber = useCallback(
    async (formData: FormData) => {
      setIsChangedPhoneNumber(false);
      if (!formData?.state) throw Error("state is not found");

      const { data } = await patchMemberPhoneNumber({
        state: formData.state,
        phoneNumber: formData.phoneNumber,
        smsCode: formData.smsCode,
      });
      if (data?.data?.member) {
        setAlertModal({ isOpen: true, message: "휴대폰 번호가 변경되었습니다." });
        setIsChangedPhoneNumber(true);
      }
    },
    [patchMemberPhoneNumber],
  );

  useEffect(() => {
    if (member?.phoneNumber) {
      setValue("phoneNumber", convertKoreanPhoneNumber(member.phoneNumber));
    }
  }, [member, setValue]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    async (data: FormData, e?: any) => {
      e.preventDefault();
      // console.log("onSubmit", data, e);

      // 휴대폰 번호 변경
      await changePhoneNumber(data);
    },
    [changePhoneNumber],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    return false;
  };

  return (
    <>
      <MetaTag title="마이페이지 | TaapSpace" />
      <Header rightDisabled={true} />

      <div className="my-page">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <article className="register">
            <div className="title-wrap">
              <h1>
                사용 중인 휴대폰 번호를
                <br />
                등록해주세요
              </h1>
            </div>

            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                <BaseTextInputWithButton
                  type="phone"
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="휴대폰 번호를 입력하세요."
                  maxLength={13}
                  actionButtonTitle={getValues("state") ? "인증 재요청" : "인증요청"}
                  onClickActionButton={() => {
                    // 인증요청
                    sendSmsVerifications(value, getValues("state"));
                  }}
                  isInvalid={!!error}
                  invalidMessage={error?.message}
                  isLoading={postSmsVerificationsLoading}
                  readOnly={isVerificationsSmsCode}
                />
              )}
            ></Controller>

            {isCompletedSendSms && (
              <div className="mt20">
                <Controller
                  control={control}
                  name="smsCode"
                  render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                    <SmsCodeInput
                      name={name}
                      value={value}
                      maxLength={6}
                      onChange={(value?: string) => {
                        onChange(value);
                        if (value && (value || "").length >= 6) {
                          // 6자리 이상일때 검증
                          checkSmsVerifications(value);
                        }
                      }}
                      placeholder="인증 번호를 입력하세요."
                      isInvalid={!!error}
                      leftComponent={
                        !isVerificationsSmsCode && mm ? (
                          <Timer
                            mm={mm}
                            ss={ss || 0}
                            closeTimer={() => {
                              resetSmsCode();
                              setAlertModal({ isOpen: true, message: "인증 유효시간이 초과되었습니다.", subMessage: "다시 요청해주세요." });
                            }}
                          />
                        ) : undefined
                      }
                      isLoading={getSmsVerificationsLoading}
                      isCompleted={isVerificationsSmsCode}
                      readOnly={isVerificationsSmsCode}
                    />
                  )}
                ></Controller>
              </div>
            )}

            <section className="mt106">
              {isChangedPhoneNumber ? (
                <BaseButton type="button" className="login-btn" title="완료" onClick={() => navigate("/court/mypage/profile")} />
              ) : (
                <BaseButton
                  type="submit"
                  className={`login-btn ${patchMemberPhoneNumberLoading ? "--loading" : ""}`}
                  disabled={!isVerificationsSmsCode}
                  title="다음"
                />
              )}
            </section>
          </article>
        </form>

        <ConfirmModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false, message: "", subMessage: "" });
          }}
          children={
            <div>
              <p className="font18 font-weight-600">{alertModal.message}</p>
              {alertModal.subMessage && <p className="font18 font-weight-600 mt20">{alertModal.subMessage}</p>}
            </div>
          }
        />
      </div>
    </>
  );
};
export default PhoneEdit;
