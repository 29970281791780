import { useNavigate } from "react-router-dom";
import { ProductModel } from "src/api/product/product-types";
import noImage from "src/assets/images/noImage_default.svg";
import { sortedByBuildingName, sortedByFacilityName, sortedPrimaryFirstBuilding } from "../../product-types";
import Link from "src/components/PartnerLink";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
type Props = {
  product: ProductModel | null;
};

const CommonFacilityInfoArea = ({ product }: Props) => {
  const buildings = product?.buildingList ? sortedPrimaryFirstBuilding(sortedByBuildingName(product?.buildingList)) : [];
  const type = product?.productType;
  const navigate = usePartnerNavigate();

  return (
    <>
      {buildings && buildings.length > 0 ? (
        <div className="product-info__building-info">
          <h2>{type === "FULL_COURT" ? "공용공간" : "원하는 공간을 선택해 주세요"}</h2>
          <section className="mt20">
            {buildings?.map((building, index) => (
              <div key={index} className="mt20">
                {buildings.length > 1 && <p className="building-title">{building.buildingName}</p>}

                {/* 회의실 */}
                {product?.isMeetingRoom &&
                  sortedByFacilityName(building.buildingCommonFacility?.meetingRoomList)?.map((facility, i: number) => (
                    <Link to={`/court/product/${product?.id}/facility/${facility.id}`} key={String(i) + facility.facilityName}>
                      <div className="">
                        <div className="top-line-container mt10">
                          <div className="top-line-container__wrap">
                            <img
                              src={
                                facility.mediaList?.filter((media: any) => media.isPrimary === true)?.[0]?.url
                                  ? facility.mediaList?.filter((media: any) => media.isPrimary === true)?.[0]?.url
                                  : facility?.mediaList && facility?.mediaList?.length > 0
                                  ? facility?.mediaList?.[0].url
                                  : noImage
                              }
                              alt={"meeting-room-img"}
                            />
                            <div className="top-line-container__info">
                              <h3>{facility.facilityName}</h3>
                              <p className="ic-users">{`정원 ${facility.facilityMaxPeopleNums}명`}</p>
                            </div>
                          </div>
                          <button></button>
                        </div>
                      </div>
                    </Link>
                  ))}

                {/* 좌석그룹 */}
                {product?.isDesk &&
                  building.buildingCommonFacility?.deskSpace?.deskGroupList?.map((deskGroup, index) => (
                    <div key={String(deskGroup.id + "deskGroup")}>
                      {sortedByFacilityName(deskGroup?.deskList)?.map((desk, index: number) => (
                        <Link to={`/court/product/${product?.id}/facility/${desk.id}`} key={String(desk.id) + String(index) + "desk"}>
                          <div className="">
                            <div className="top-line-container mt10">
                              <div className="top-line-container__wrap">
                                <img
                                  src={
                                    deskGroup.mediaList?.filter((media) => media.isPrimary === true)?.[0]?.url
                                      ? deskGroup.mediaList?.filter((media) => media.isPrimary === true)?.[0]?.url
                                      : deskGroup.mediaList && deskGroup.mediaList.length > 0
                                      ? deskGroup.mediaList[0].url
                                      : noImage
                                  }
                                  alt={"meeting-room-img"}
                                />
                                <div className="top-line-container__info">
                                  <h3>{desk.facilityName}</h3>
                                  <p className="ic-users">{`정원 1명`}</p>
                                </div>
                              </div>
                              <button></button>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ))}

                {/* 편의시설 */}
                {product?.isRefreshRoom &&
                  sortedByFacilityName(building.buildingCommonFacility?.refreshRoomList)?.map((refreshRoom) => (
                    <Link to={`/court/product/${product?.id}/facility/${refreshRoom.id}`} key={String(refreshRoom.id) + "refreshRoom"}>
                      <div className="">
                        <div className="top-line-container mt10">
                          <div className="top-line-container__wrap">
                            <img src={refreshRoom.mediaList?.[0]?.url || noImage} alt={"meeting-room-img"} />
                            <div className="top-line-container__info">
                              <h3>{refreshRoom.facilityName}</h3>
                              <p className="ic-users">{`정원 ${refreshRoom.facilityMaxPeopleNums}명`}</p>
                            </div>
                          </div>
                          <button></button>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            ))}
          </section>
        </div>
      ) : null}
    </>
  );
};

export default CommonFacilityInfoArea;
