type Props = React.ComponentProps<"button"> & {
  isLoading?: boolean;
  delay?: number;
};

/**
 * 버튼 공통 컴포넌트
 */
const BaseButton = ({ title, type = "button", isLoading, disabled, className, onClick, delay }: Props) => {
  let _className = "login-btn"; // 기본 class
  if (className) {
    _className = className;
  }
  if (isLoading) {
    _className += " --loading";
  }

  let timerId: any = null;

  const onThrottleClick = (e: any, delay: number) => {
    // timerId가 있으면 바로 종료
    if (timerId) {
      return;
    }
    // console.log(`API요청 실행! ${delay}ms 동안 추가요청 안받음`);
    timerId = setTimeout(() => {
      if (onClick) {
        // console.log(`${delay}ms 지남 추가요청 받음`);
        onClick(e);
        timerId = null;
      }
    }, delay);
  };

  return (
    <button type={type} className={_className} disabled={disabled || isLoading} onClick={(e: any) => onThrottleClick(e, delay || 0)}>
      <span>{title}</span>
    </button>
  );
};
export default BaseButton;
