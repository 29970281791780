import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { getMemberContractsAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { postWithdrawalAsync } from "src/api/member/member-api";
import { deleteReservationAsync, getReservationsAsync } from "src/api/reservation/reservation-api";
import { AcknowledgeReservation } from "src/api/reservation/reservation-type";
import { getVisitorListAsync, patchVisitorDeleteAsync } from "src/api/visitor/visitor-api";
import { BaseButton, ConfirmModal } from "src/components";
import { BaseCheckbox } from "src/components/BaseCheckbox";
import Header from "src/components/layout/Header";
import { Modal } from "src/pages/contract/contract-types";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { useMemberInfoState } from "src/recoil/member/hook";
import { YmdFormat } from "src/utils/common-util";

// 회원탈퇴 > 유효한 계약이 있는 페이지
type Agree = {
  first: boolean;
  second: boolean;
  third: boolean;
};
const ExistReservation = () => {
  const [agrees, setAgrees] = useState<Agree>({ first: false, second: false, third: false });
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });
  const [contracts, setContracts] = useState<any[]>([]);
  const [reservations, setReservations] = useState<AcknowledgeReservation[]>([]);
  const [visitors, setVisitors] = useState<any[]>([]);
  const { goBack } = useNavigateGoBack();
  const navigate = usePartnerNavigate();
  const { logout } = useMemberInfoState();

  const { member } = useMemberInfoState();
  const { executeAsync: getContracts } = useApiOperation(getMemberContractsAsync);
  const { executeAsync: getReservations } = useApiOperation(getReservationsAsync);
  const { executeAsync: deleteReservation } = useApiOperation(deleteReservationAsync);
  const { executeAsync: getVisitorList } = useApiOperation(getVisitorListAsync);
  const { executeAsync: patchVisitorDelete } = useApiOperation(patchVisitorDeleteAsync);
  const { executeAsync: postWithdrawal } = useApiOperation(postWithdrawalAsync);

  // 방문자초대 조회
  const fetchGetVisitorList = useCallback(async () => {
    if (member?.memberNo) {
      const { data: visitorData, status: visitorStatus } = await getVisitorList({
        inviteeMemberNo: member?.memberNo,
        visitStatus: "VISIT_USE",
        searchStartTime: moment().format(YmdFormat.WITH_TIME_ZONE),
        searchEndTime: "9999-12-31T23:59:59.999+09:00",
      });
      if (visitorStatus >= 200 && visitorStatus < 300 && visitorData.data) {
        setVisitors(visitorData.data.contractVisitor);
      }
    }
  }, [getVisitorList, member?.memberNo]);

  // 공용공간예약 조회
  const fetchGetReservationList = useCallback(async () => {
    if (member?.memberNo) {
      const { data: reservationData, status: reservationStatus } = await getReservations({
        statusCode: "ACCEPTED,ACKNOWLEDGED,INCOMPLETE",
        organizerOnly: true,
        greaterThanEqualsEnd: moment().format(YmdFormat.WITH_TIME_ZONE),
      });
      if (reservationStatus >= 200 && reservationStatus < 300 && reservationData.data.content) {
        setReservations(reservationData.data.content);
      }
    }
  }, [getReservations, member?.memberNo]);

  useEffect(() => {
    const fetchApi = async () => {
      // 계약 조회
      if (member?.memberNo) {
        const { data: contractData, status: contractStatus } = await getContracts({
          memberNo: member?.memberNo,
          contractSteps: "APPLY_RECEIVED,APPLY_CONFIRM,CONTRACT_PLAN,CONTRACT_ACCEPT,USE_APPROVAL,USE_PROGRESS,TERMINATE_RECEIVED",
        });
        if (contractStatus >= 200 && contractStatus < 300 && contractData.data.content) {
          const contractList = contractData.data.content.filter((contract) => contract.memberStatus === "MEMBER_USE");
          setContracts(contractList);
        }
        fetchGetVisitorList();
        fetchGetReservationList();
      }
    };
    fetchApi();
  }, [member, getContracts, fetchGetReservationList, fetchGetVisitorList]);

  const handleDeleteReservations = async () => {
    const promises = reservations.map((reservation) => {
      return deleteReservation({ reservationId: String(reservation.id) });
    });
    await Promise.all(promises);
    setConfirmModal({ isOpen: false });
    setDeleteModal({ isOpen: true, type: "DELETE_RESERVATIONS" });
    fetchGetReservationList();
  };
  const handleDeleteVisitors = async () => {
    if (member?.memberNo) {
      const contractVisitorIds = visitors.map((visitor) => visitor.contractVisitorId);
      await patchVisitorDelete({ inviteeMemberNo: member?.memberNo, contractVisitorIds });
      setConfirmModal({ isOpen: false });
      setDeleteModal({ isOpen: true, type: "DELETE_VISITORS" });
      fetchGetVisitorList();
    }
  };

  const handleWithdrawal = async () => {
    const { status } = await postWithdrawal();
    if (status >= 200 && status < 300) {
      logout();
      navigate("/court/mypage/profile/withdrawalComplete");
    }
  };

  return (
    <div className="withdrawal-page">
      <Header headerType="BACK" />
      <div className="base-detail-layout">
        <h2>유효한 계약이 있습니다</h2>
        <p className="desc">
          잔여 공용공간 예약 및 방문자 초대가 없다면
          <br />
          회원탈퇴가 가능합니다
          <br />
          아래 내역을 확인해주세요
        </p>
        <section className="gray-wrap">
          <div className="gray-wrap__box">
            <p>유효한 계약 : {contracts.length}건</p>
          </div>
          <div className="gray-wrap__box">
            <p>잔여 공용공간 예약 : {reservations.length}건</p>
            <button disabled={!!!reservations.length} onClick={() => setConfirmModal({ isOpen: true, type: "DELETE_RESERVATIONS" })}>
              삭제
            </button>
          </div>
          <div className="gray-wrap__box">
            <p>잔여 방문자 초대 : {visitors.length}건</p>
            <button disabled={!!!visitors.length} onClick={() => setConfirmModal({ isOpen: true, type: "DELETE_VISITORS" })}>
              삭제
            </button>
          </div>
        </section>
        <section className="mt20">
          <BaseCheckbox
            id={"first"}
            name={"first"}
            checked={agrees.first}
            className="flex-center mb10"
            onChange={() => {
              setAgrees((prev) => {
                return { ...prev, first: !prev.first };
              });
            }}
          >
            <p className="ml10 font16 font-weight-600">[필수] 회원탈퇴 즉시 모든 서비스 이용이 불가합니다.</p>
          </BaseCheckbox>
          <BaseCheckbox
            id={"second"}
            name={"second"}
            checked={agrees.second}
            className="flex-center mb10"
            onChange={() => {
              setAgrees((prev) => {
                return { ...prev, second: !prev.second };
              });
            }}
          >
            <p className="ml10 font16 font-weight-600">[필수] 모든 신청/계약에서 이용자 권한이 삭제됩니다.</p>
          </BaseCheckbox>
          <BaseCheckbox
            id={"third"}
            name={"third"}
            checked={agrees.third}
            className="flex-center mb10"
            onChange={() => {
              setAgrees((prev) => {
                return { ...prev, third: !prev.third };
              });
            }}
          >
            <p className="ml10 font16 font-weight-600">[필수] 탈퇴 즉시 이전 이용내역은 복구할 수 없습니다.</p>
          </BaseCheckbox>
        </section>
      </div>
      {confirmModal.isOpen && (
        <ConfirmModal
          isOpen={true}
          btnRightTitle={"확인"}
          btnLeftTitle={"취소"}
          onClose={() => {
            setConfirmModal({ isOpen: false });
          }}
          onClick={() => {
            confirmModal.type === "DELETE_RESERVATIONS" ? handleDeleteReservations() : handleDeleteVisitors();
          }}
        >
          {confirmModal.type === "DELETE_RESERVATIONS" ? (
            <h2 className="font18 font-weight-600">
              잔여 공용공간 예약이 있습니다
              <br /> 모두 삭제하시겠습니까?
            </h2>
          ) : (
            <h2 className="font18 font-weight-600">
              잔여 방문자 초대가 있습니다
              <br />
              모두 삭제하시겠습니까?
            </h2>
          )}
        </ConfirmModal>
      )}
      {deleteModal.isOpen && (
        <ConfirmModal
          isOpen={true}
          btnRightTitle={"확인"}
          onClick={() => {
            setDeleteModal({ isOpen: false });
          }}
        >
          {deleteModal.type === "DELETE_RESERVATIONS" ? (
            <h2 className="font18 font-weight-600">잔여 공용공간 예약이 모두 삭제되었습니다</h2>
          ) : (
            <h2 className="font18 font-weight-600">잔여 방문자 초대가 모두 삭제되었습니다</h2>
          )}
        </ConfirmModal>
      )}
      <div className="btn-wrap">
        <BaseButton title="취소" className="base-btn color-white" onClick={() => goBack()}></BaseButton>
        <BaseButton
          title="회원 탈퇴하기"
          className="base-btn"
          disabled={!agrees.first || !agrees.second || !agrees.third || reservations.length > 0 || visitors.length > 0}
          onClick={handleWithdrawal}
        ></BaseButton>
      </div>
    </div>
  );
};

export default ExistReservation;
