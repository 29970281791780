import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CommonFacilityModel, DeskGroupModel } from "src/api/building/building-types";
import { getDeskgroupsAsync, getDesksAsync } from "src/api/building/deskgroup-api";
import { useApiOperation } from "src/api/hooks";
import { BaseBottomSheet, ConfirmModal } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import usePostMessage from "../hooks/post-message";

// 예약가능한 좌석그룹
type AvailableDeskGroup = {
  deskGroupId: string; // 좌석그룹 id
  availableDeskIds: string; // 해당 좌석그룹에 예약가능한 좌석 id 들 (콤마로 구분)
  selectedDeskId?: string; // 예약가능한 좌석 id 들 중, 예약되어있는 좌석 id
};

type Modal = {
  isOpen: boolean;
  message?: string;
};

// zoom scale 최소 값
const TRANSFORM_MIN_SCALE = 0.6;

// {위치} {좌석 그룹명}
function getDeskGroupLabel(deskGroupModel?: DeskGroupModel | null): string {
  if (!deskGroupModel) return "";
  let label = "";
  label += deskGroupModel?.isGround ? `${Number(deskGroupModel?.floorNum || 0)}F` : `B${Number(deskGroupModel?.floorNum || 0)}`;
  label += " " + deskGroupModel?.groupName;
  return label;
}

/**
 * 좌석배치도 Taap 웹뷰 화면
 */
const SeatingChart = () => {
  // window.court.webViewFunction.setAvailableDeskGroups 초기화 여부 ref
  const initializedRef = useRef<boolean>(false);
  // 좌석 그룹 목록 조회 여부 ref
  const fetchDeskgroupsRef = useRef<boolean>(false);
  // 좌석배치도 img ref
  const imgRef = useRef<HTMLImageElement>(null);
  // TransformWrapper ref
  const transformWrapperRef = useRef(null);

  // (무인증) 좌석 그룹 목록 조회 api
  const { executeAsync: getDeskgroups } = useApiOperation(getDeskgroupsAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 좌석 그룹별 좌석 목록 조회 api
  const { executeAsync: getDesks } = useApiOperation(getDesksAsync, { noAuthenticationRequired: true, noHandleError: true });

  // ReactNativeWebView postMessage 를 관리하는 hook
  const { postMessageInitializedSetAvailableDeskGroups, postMessageGoBackNavigation, postMessageSelectedDesk } = usePostMessage();

  // query parameter
  const [searchParams] = useSearchParams();

  // query parameter 건물 id
  const buildingId: string | null = searchParams.get("buildingId");

  // query parameter 좌석 그룹 id
  const deskGroupId: string | null = searchParams.get("deskGroupId");

  // query parameter 좌석배치도만 보여주고 싶을 경우 true
  const isOnlySeatingChart: boolean = searchParams.get("isOnlySeatingChart") === "true";

  // window width
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  // 좌석 배치도 이미지 witdh
  const [imageWidth, setImageWidth] = useState<number>(0);

  // 좌석 그룹 목록
  const [deskGroups, setDeskGroups] = useState<DeskGroupModel[]>([]);

  // 좌석 목록
  const [deskList, setDeskList] = useState<CommonFacilityModel[]>([]);

  // 좌석그룹목록 bottom sheet 오픈 여부
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false);

  // 선택되어있는 좌석그룹 id
  const [selectedDeskGroupId, setSelectedDeskGroupId] = useState<string>("");

  // 선택한 좌석 id
  const [selectedDeskId, setSelectedDeskId] = useState<string>("");

  // 예약가능한 좌석그룹 목록
  const [availableDeskGroups, setAvailableDeskGroups] = useState<AvailableDeskGroup[]>([]);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 좌석배치도 이미지 로드를 실패했을 경우 true
  const [isLoadErrorImage, setIsLoadErrorImage] = useState<boolean>(false);

  // zoom scale 계산
  const calculateScale = useCallback(
    (_innerWidth: number, _imageWidth: number) => {
      // 48 은 Taap 에서의 양쪽 padding (24 x 2)
      const windowWidth = isOnlySeatingChart ? _innerWidth - 48 : _innerWidth;

      // .base-layout-container max-width: 500px 보다 크면
      if (windowWidth >= 500) {
        return 0.9;
      }

      // 이미지 width 가 window width 보다 크면
      if (_imageWidth > windowWidth) {
        return Number((windowWidth / _imageWidth).toFixed(2));
      }

      // 이미지 width 가 window width 보다 작으면
      const scale = Number((_imageWidth / windowWidth).toFixed(2));
      // 최소 scale 보다 작으면
      if (scale < TRANSFORM_MIN_SCALE) {
        return TRANSFORM_MIN_SCALE;
      }

      return scale;
    },
    [isOnlySeatingChart],
  );

  // 좌석 그룹별 좌석 목록 조회
  const fetchDesks = useCallback(
    async (buildingId: string | number, deskGroupId: string | number) => {
      const { data } = await getDesks({ buildingId, deskGroupId });
      const _deskGroup = data?.data?.content || null;

      // 좌석 좌표가 있는 것만 필터링
      const filterdDeskList = (_deskGroup?.deskList || []).filter(
        (desk: CommonFacilityModel) => Number(desk?.centerX || 0) > 0 && Number(desk?.centerY || 0) > 0,
      );
      setDeskList(filterdDeskList);
    },
    [getDesks],
  );

  // 예약가능한 좌석그룹 목록 바인딩
  const setAvailableDeskGroupsCallback = useCallback(
    async (_availableDeskGroups?: string) => {
      if (!buildingId) throw Error("buildingId is not found");
      const receivedAvailableDeskGroups = _availableDeskGroups ? JSON.parse(_availableDeskGroups) : [];

      // 전체 좌석그룹목록
      const allDeskGroups = deskGroups || [];

      // 예약가능한 좌석그룹 목록
      const filteredAvailableDeskGroups: AvailableDeskGroup[] = [];
      receivedAvailableDeskGroups.forEach((a: AvailableDeskGroup) => {
        // 예약가능한 좌석그룹 중에서 전체 좌석그룹에서 존재하는 것만 필터링
        const find = allDeskGroups.find((d: DeskGroupModel) => a.deskGroupId === d.id);
        if (find) {
          filteredAvailableDeskGroups.push(a);
        }
      });
      setAvailableDeskGroups(filteredAvailableDeskGroups);

      // 좌석그룹 선택
      let selectDeskGroupId = "";
      if (filteredAvailableDeskGroups.length > 0) {
        if (!deskGroupId) {
          // 선택한 좌석그룹이 없을 경우 첫번째 좌석그룹 선택
          selectDeskGroupId = filteredAvailableDeskGroups[0].deskGroupId;
        } else {
          selectDeskGroupId = deskGroupId;
        }
      }

      if (selectDeskGroupId) {
        setSelectedDeskGroupId(selectDeskGroupId);

        // 좌석 선택
        const findDeskGroup = filteredAvailableDeskGroups.find(
          (_availableDeskGroup) => String(_availableDeskGroup.deskGroupId) === selectDeskGroupId,
        );
        if (findDeskGroup) {
          if (findDeskGroup.selectedDeskId) {
            // 기존에 예약되어있는 좌석이 있을 경우 선택 표기
            setSelectedDeskId(findDeskGroup.selectedDeskId);
          }
        }
      }
    },
    [deskGroups, buildingId, deskGroupId],
  );

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // function 초기화가 되어있지않고, 전체 좌석그룹 목록을 조회를 했다면
    // function 초기화하고, RN 에 post message initializedSetAvailableDeskGroups 전달
    if (!initializedRef.current && fetchDeskgroupsRef.current) {
      // setAvailableDeskGroups global function 초기화 (재할당)
      window.court.webViewFunction.setAvailableDeskGroups = setAvailableDeskGroupsCallback;
      postMessageInitializedSetAvailableDeskGroups();
      initializedRef.current = true;
    }
  }, [setAvailableDeskGroupsCallback, postMessageInitializedSetAvailableDeskGroups]);

  // 좌석 그룹 목록 조회
  const fetchDeskgroups = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getDeskgroups({ buildingId });
      const _deskGroups = data?.data?.content || [];
      setDeskGroups(_deskGroups);
      fetchDeskgroupsRef.current = true;
    },
    [getDeskgroups],
  );

  // 좌석그룹 변경
  const onChangeSelectedDeskGroup = useCallback(
    async (_deskGroupId: string) => {
      if (buildingId) {
        setIsOpenBottomSheet(false);
        setSelectedDeskGroupId(_deskGroupId);
        setSelectedDeskId("");

        if (transformWrapperRef.current) {
          // zoom center 로 이동
          const scale = calculateScale(innerWidth, imageWidth);
          (transformWrapperRef.current as any)?.centerView(scale);
        }
      }
    },
    [buildingId, innerWidth, imageWidth],
  );

  useEffect(() => {
    // 건물 id 변경될 때
    if (buildingId) {
      // 해당 건물의 좌석그룹목록 조회
      fetchDeskgroups(buildingId);
    }
  }, [buildingId]);

  useEffect(() => {
    // 좌석그룹 선택이 변경될때
    if (buildingId && selectedDeskGroupId) {
      // 선택한 좌석그룹의 좌석목록 조회
      fetchDesks(buildingId, selectedDeskGroupId);
    }
  }, [buildingId, selectedDeskGroupId]);

  // 선택되어있는 좌석 그룹
  const selectedDeskGroup: DeskGroupModel | undefined = useMemo(() => {
    if (availableDeskGroups.length === 0) return undefined;
    // 예약가능한 좌석 그룹만 필터링
    const filteredAvailableDeskGroups = deskGroups.filter((deskGroup: DeskGroupModel) => {
      const availableDeskGroupIds = availableDeskGroups.map((v: AvailableDeskGroup) => v.deskGroupId);
      return availableDeskGroupIds.includes(deskGroup.id);
    });
    if (!selectedDeskGroupId) {
      // 선택되어있는 좌석그룹이 없을 경우 첫번째 좌석 그룹 선택
      return filteredAvailableDeskGroups[0];
    } else {
      return filteredAvailableDeskGroups.find((deskGroup: DeskGroupModel) => deskGroup.id === selectedDeskGroupId);
    }
  }, [selectedDeskGroupId, deskGroups, availableDeskGroups]);

  // 좌석그룹의 배치도 이미지 url
  const seatingChartImageUrl: string | undefined = useMemo(() => {
    return (selectedDeskGroup?.mediaList || [])[0]?.url;
  }, [selectedDeskGroup]);

  // 선택 버튼 disabled 여부
  const isDisabledSelectButton = useMemo(() => {
    return !selectedDeskId || deskList.length === 0 || !deskList.find((desk) => String(desk.id) === String(selectedDeskId));
  }, [selectedDeskId, deskList]);

  // 예약가능한 좌석 여부
  const isAvailableDesk = useCallback(
    (deskId: string) => {
      if (!availableDeskGroups || availableDeskGroups.length === 0) return false;
      const findDeskGroup = availableDeskGroups.find((v: AvailableDeskGroup) => String(v.deskGroupId) === String(selectedDeskGroupId));
      if (!findDeskGroup || !findDeskGroup?.availableDeskIds) return false;
      return findDeskGroup.availableDeskIds.split(",").includes(deskId);
    },
    [selectedDeskGroupId, availableDeskGroups],
  );

  // 선택버튼 클릭
  const onClickSelect = useCallback(() => {
    if (!selectedDeskGroupId) {
      // 좌석그룹 선택되어있지 않은 경우 웹뷰 닫기
      postMessageGoBackNavigation();
      return;
    }

    if (!selectedDeskId) {
      setAlertModal({ isOpen: true, message: "좌석을 선택해주세요" });
      return;
    }

    // RN 에 선택한 좌석그룹, 좌석 정보 전달
    postMessageSelectedDesk({
      deskGroupId: selectedDeskGroupId,
      deskId: selectedDeskId,
    });
  }, [selectedDeskId, selectedDeskGroupId, postMessageGoBackNavigation, postMessageSelectedDesk]);

  // 예약가능한 좌석그룹목록이 없을 경우
  if (availableDeskGroups.length === 0) return null;

  return (
    <div>
      <MetaTag title="좌석배치도 | TaapSpace" />

      {/* 좌석배치도만 보여주고 싶을 경우, 헤더 영역은 그려주지 않는다 */}
      {!isOnlySeatingChart && (
        <section className="back-header-wrap">
          <div className="back-header">
            <div className="back-header__left">
              <button
                type="button"
                className="back-btn"
                onClick={() => {
                  postMessageGoBackNavigation();
                }}
              ></button>
            </div>
            <button
              type="button"
              className={`chevron-down-btn ellipsis ${isOpenBottomSheet ? "--active" : ""}`}
              disabled={availableDeskGroups.length === 0 || availableDeskGroups.length === 1}
              onClick={() => {
                setIsOpenBottomSheet(true);
              }}
            >
              {getDeskGroupLabel(selectedDeskGroup)}
            </button>
            {availableDeskGroups.length > 0 && (
              <div className="back-header__right">
                <button type="button" className="header-text-btn" onClick={() => onClickSelect()} disabled={isDisabledSelectButton}>
                  선택
                </button>
              </div>
            )}
          </div>
        </section>
      )}

      {seatingChartImageUrl ? (
        // 좌석배치도 이미지 있는 경우
        <>
          {isLoadErrorImage ? (
            // 좌석배치도 이미지 로딩하다가 오류 발생한 경우
            <div className="seat-chart">
              <section>
                <div className="seat-container-zoom with-header">
                  <div className="snack">
                    <div className="ic-snack"></div>
                    <h2>Play work on Taap</h2>
                    <p>오늘도 응원할게요!!</p>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            // 좌석배치도 이미지 로딩 성공한 경우
            <TransformWrapper doubleClick={{ disabled: true }} minScale={TRANSFORM_MIN_SCALE} maxScale={2} ref={transformWrapperRef}>
              {({ centerView }) => (
                <div className="seat-chart">
                  <section>
                    <div className={`seat-container-zoom ${isOnlySeatingChart ? "" : "with-header"}`}>
                      <TransformComponent>
                        <div className="seat-container">
                          {/* 좌석 컴포넌트들 */}
                          {deskList.map((desk: CommonFacilityModel) => (
                            <button
                              type="button"
                              className={`desc-comp-btn ${String(selectedDeskId) === String(desk.id) ? "--active" : ""}`}
                              key={desk.id}
                              style={{ left: Number(desk.centerX || 0), top: Number(desk.centerY || 0) }}
                              onClick={() => {
                                if (!isOnlySeatingChart) {
                                  // 좌석배치도만 보여주고 싶을 경우를 제외하고, 좌석 선택 가능하게
                                  setSelectedDeskId(String(desk.id));
                                }
                              }}
                              disabled={isOnlySeatingChart ? false : !isAvailableDesk(String(desk.id))}
                            >
                              {desk.facilityName}
                            </button>
                          ))}

                          <img
                            ref={imgRef}
                            src={seatingChartImageUrl}
                            alt="좌석배치도 이미지"
                            onLoad={() => {
                              if (imgRef.current) {
                                // 이미지가 로드 되었을때, 이미지 width 구하기
                                const _imageWidth = imgRef.current.clientWidth;
                                setImageWidth(_imageWidth);

                                // 이미지 width 로 계산된 zoom scale 계산
                                const scale = calculateScale(innerWidth, _imageWidth);
                                centerView(scale);
                              }
                            }}
                            onError={(e) => {
                              // 좌석 배치도 이미지 로드 실패
                              setIsLoadErrorImage(true);
                            }}
                          />
                        </div>
                      </TransformComponent>
                    </div>
                  </section>
                </div>
              )}
            </TransformWrapper>
          )}
        </>
      ) : (
        // 좌석배치도 이미지 없는 경우
        <div className="error-page with-header">
          <section>
            <div className="error-icon ic-none"></div>
            <div className="error-message-wrap">
              <h2>해당 배치도를 이용할 수 없습니다</h2>
              <p>리스트에서 좌석을 선택해 주세요</p>
            </div>
          </section>
        </div>
      )}

      {/* 좌석그룹 선택 바텀시트 */}
      <BaseBottomSheet isOpen={isOpenBottomSheet} onClose={() => setIsOpenBottomSheet(false)} title={"구역을 선택해주세요"} className="scrollable">
        <div className="section-select-sheet">
          <ul className="section-list">
            {availableDeskGroups.map((deskGroup: AvailableDeskGroup, index: number) => (
              <li
                key={index.toString()}
                className={`section-list__item ${deskGroup.deskGroupId === selectedDeskGroupId ? "--active" : ""}`}
                onClick={() => {
                  onChangeSelectedDeskGroup(deskGroup.deskGroupId);
                }}
              >
                {getDeskGroupLabel(deskGroups.find((d: DeskGroupModel) => d.id === deskGroup.deskGroupId))}
              </li>
            ))}
          </ul>
        </div>
      </BaseBottomSheet>

      <ConfirmModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => {
          setAlertModal({ isOpen: false, message: "" });
        }}
        children={
          <div>
            <p className="font18 font-weight-600">{alertModal.message}</p>
          </div>
        }
      />
    </div>
  );
};

export default SeatingChart;
