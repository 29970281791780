import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { postVisitorInvitationAsync } from "src/api/visitor/visitor-api";
import { BaseBottomSheet, BaseButton, ConfirmModal } from "src/components";
import BaseAbstractModal from "src/components/BaseAbstractModal";
import MetaTag from "src/components/layout/MetaTag";
import { validatePhoneNumber } from "src/utils/common-util";
import { getPurposeLabel, purposeSelectOptions } from "../visitor-types";
import TermsOfUseModal from "./TermsOfUseModal";

type FormData = {
  visitorMobileNumber: string; // 방문자 휴대폰번호
  visitPurpose: string; // 방문목적
};

type QueryParams = {
  contractManageId?: string;
  buildingId?: string;
  encryptedContractManageId?: string;
  encryptedBuildingId?: string;
};

/**
 * 방문자 셀프 등록
 */

// feature 1 :: 암호회된 url로 form 페이지 진입
// feature 2 :: location.search로 queryParams 획득
// feature 3 :: 암호화된 id들(encryptedContractManageId, encryptedBuildingId)을 path url로, 방문자 정보 body에 담아서 post
// feature 4 :: 성공시 성공 화면으로 이동, 실패시 ?

// test url :: http://dev-court.pnpt.net/front/court/visitor/form?contractManageId=Q2NyVUV5WmRZcDZTVngzY1IxNGpxSzVsdXkwb0ZMa2p0WEFGQVF2bDRvQU05YUtPZm4xTU5Sc2NjODZ2UUpqSHZGNFQrVExhamg5dVNZclkza2RCZ3hkcER0NVdhcU9IRXdQRFozNXFhRU9tUmptOUhlUDJKVnRFejBBRjNiYXZFdWFhczJnMHM2VUN1cjIwdFY0RFV2YkY4WG4xV21ZTXp1V1lkVitBYzV3PQ&buildingId=T0JSc0JEMUZyb05XMTVFVDJHYVJwZnEvNnh5R0VIWFlEUnFraUI1N2RTZWpMdTRSVEZDY0Q4MWFSVU40Yzh5RFk0bXhUTXVNZ01ueFhKQlF4L2xESDRHR2JOZjJ6Q3U0bWdsTE9HUXVGekw1bmNTQUxuSGlvVTJmWGVEc2xqbDB6NU9GWm1RUGhNc0tTaFNFKzRxRk1ZZDhUOEYzV0I4aEJsS202dlJrbmdJPQ

const VisitorForm = () => {
  const [isVisitPurposeBottomSheet, setIsVisitPurposeBottomSheet] = useState(false);
  const [isTermsOfUseModal, setIsTermsOfUseModal] = useState(false);
  const [isAgreeTermsOfUse, setIsAgreeTermsOfUse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { executeAsync: postVisitorInvitation } = useApiOperation(postVisitorInvitationAsync, { noAuthenticationRequired: true });
  const location = useLocation();
  const navigate = useNavigate();
  const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

  // queryParam
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, []);

  const defaultValues = useMemo(() => {
    return {
      visitorMobileNumber: "",
      visitPurpose: "",
    };
  }, []);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    register("visitPurpose", {
      required: "",
    });
    register("visitorMobileNumber", {
      validate: {
        validatePhoneNumber: (phoneNumber?: string) => {
          return validatePhoneNumber(phoneNumber);
        },
      },
    });
  }, [register]);

  // 완료 버튼 눌렀을 때 fetching
  const onSubmit = async (data: FormData, e?: any) => {
    setIsLoading(true);
    const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey!, { action: "ctrlroom/work_order/page_load" });

    const newData = {
      ...data,
      visitorMobileNumber: data.visitorMobileNumber.replace(/-/g, ""),
      encryptedContractManageId: queryParams.contractManageId || "",
      encryptedBuildingId: queryParams.buildingId || "",
      token: token,
    };

    const res = await postVisitorInvitation(newData);
    if (res.status >= 200 && res.status <= 299) {
      navigate("/court/visitor/complete");
    }
    setIsLoading(false);
  };

  return (
    <>
      <MetaTag title="방문자 셀프 등록 | TaapSpace" isReCAPTCHA />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="visitor-form">
          <section className="visitor-form__logo">
            <div className="dears_pangyo"></div>
          </section>
          <section className="visitor-form__intro">
            <h1>셀프 방문객 정보 등록</h1>
            <p>
              반갑습니다! 디어스 판교지점입니다.
              <br /> 초대를 제외한 방문객은 정보 등록 후 출입할 수 있습니다.
            </p>
          </section>
          <section className="visitor-form__form">
            <Controller
              control={control}
              name="visitPurpose"
              render={({ field: { value } }) => (
                <div className="select-div" onClick={() => setIsVisitPurposeBottomSheet(true)}>
                  {value ? <p className="text-primary1">{getPurposeLabel(value)}</p> : <p>어떤 목적으로 방문하셨나요?</p>}
                </div>
              )}
            ></Controller>
            <div className="mt20">
              <Controller
                control={control}
                name="visitorMobileNumber"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <div className="base-input-wrap">
                    <input
                      type="number"
                      value={value}
                      pattern="\\d*"
                      name={name}
                      onBlur={(e) => {
                        onChange && onChange(e.target.value.trim(), e);
                      }}
                      onChange={onChange}
                      className={error?.message ? "base-input border-bottom-red" : "base-input"}
                      placeholder="휴대폰 번호를 입력해주세요."
                    />
                    {value.length > 0 && error?.message && (
                      <button
                        className="clear-btn"
                        type="button"
                        onClick={() => {
                          setValue("visitorMobileNumber", "");
                          trigger("visitorMobileNumber");
                        }}
                      ></button>
                    )}
                    {value.length > 0 && !error?.message && <button type="button" className="check-green-btn"></button>}
                    {error?.message && (
                      <div className="validation-wrap">
                        <div className="validation-wrap__left">
                          <p className="validation-text">{error.message}</p>
                        </div>
                        <div className="validation-wrap__right"></div>
                      </div>
                    )}
                  </div>
                )}
              ></Controller>
            </div>
            <div className="mt50 mb80">
              <input
                className="checkbox"
                type="checkbox"
                name="isAgreeTermsOfUse"
                id="isAgreeTermsOfUse"
                checked={isAgreeTermsOfUse}
                onChange={(e) => {
                  setIsAgreeTermsOfUse(e.target.checked);
                }}
              />
              <label htmlFor="isAgreeTermsOfUse" className="font16 flex-center">
                <div className="base-checkbox"></div>
                <p className="ml10 font14 text-primary3">
                  서비스 이용을 위해{" "}
                  <u
                    className="cursor-pointer"
                    onClick={(e) => {
                      setIsTermsOfUseModal(true);
                      e.preventDefault();
                    }}
                  >
                    개인정보 이용약관
                  </u>
                  에 동의합니다.
                </p>
              </label>
            </div>
            <BaseButton
              type="submit"
              isLoading={isLoading}
              className="base-btn mb20"
              disabled={!(getValues("visitPurpose") !== "") || !isValid || !isAgreeTermsOfUse || isLoading}
              title={"완료"}
            />
            <p className="desc">
              ⚠️ <span className="ml6">방문 유효 시간은 1시간입니다.</span>
            </p>
          </section>
          {isVisitPurposeBottomSheet && (
            <BaseBottomSheet
              isOpen={isVisitPurposeBottomSheet}
              onClose={() => setIsVisitPurposeBottomSheet(false)}
              title={"방문 목적을 선택해주세요"}
              className="scrollable"
            >
              <div className="visit-purpose-sheet">
                <div className="purpose-list">
                  {purposeSelectOptions.map((purpose) => (
                    <div
                      key={purpose.value}
                      className={getValues("visitPurpose") === purpose.value ? "purpose-list__item --active" : "purpose-list__item"}
                      onClick={() => {
                        setValue("visitPurpose", purpose.value);
                        setIsVisitPurposeBottomSheet(false);
                      }}
                    >
                      {purpose.label}
                    </div>
                  ))}
                </div>
              </div>
            </BaseBottomSheet>
          )}
          {isError && (
            <ConfirmModal isOpen={isError} btnRightTitle={"확인"} onClick={() => setIsError(false)}>
              <p className="font18">유효하지 않은 url 입니다.</p>
            </ConfirmModal>
          )}

          {isTermsOfUseModal && <TermsOfUseModal isOpen={isTermsOfUseModal} onClick={() => setIsTermsOfUseModal(false)} />}
        </div>
      </form>
    </>
  );
};

export default VisitorForm;
