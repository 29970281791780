import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { useApiOperation } from "src/api/hooks";
import { getMemberMeAsync } from "src/api/member/member-api";
import { useCourtAuth } from "src/components/court-auth";
import log from "src/utils/log";
import { memberInfoState, MemberInfoState } from "./atom";

/**
 * 로그인한 회원정보를 관리하는 hook
 */
export const useMemberInfoState = () => {
  const [memberInfo, setMemberInfo] = useRecoilState<MemberInfoState>(memberInfoState);
  const { authorize, revoke, security } = useCourtAuth();

  // 로그인한 회원 내 프로필 조회 api
  const { executeAsync: getMemberMe, loading: isMemberLoading } = useApiOperation(getMemberMeAsync);

  // 로그인한 회원 내 프로필 조회
  const fetchMemberMe = useCallback(async () => {
    try {
      // 인증된 상태, 휴대폰 인증 후 회원 가입 완료한 사용자, recoil state 에 member 정보가 없을 경우만 조회
      if (security.status === "authenticated" && !security.user?.roles.includes("ROLE_STARTER") && !memberInfo?.member) {
        log("useMemberInfoState fetchMemberMe");
        // 없을 경우만 조회
        const { data } = await getMemberMe();
        if (data?.data?.member) {
          setMemberInfo({ member: data.data.member });
        }
      }
    } catch (error) {
      console.error("fetchMemberMe error", error);
    }
  }, [security, memberInfo, getMemberMe]);

  // memberInfoState > member
  const member = useMemo(() => {
    if (!memberInfo || !memberInfo.member) return null;
    return memberInfo.member;
  }, [memberInfo]);

  // 로그인
  const login = useCallback(async () => {
    // 인증
    await authorize({ replace: true, prompt: "login" });
  }, [authorize]);

  // 로그아웃
  const logout = useCallback(async () => {
    // 인증 revoke
    await revoke();
    // recoil state member 정보 null 로 업데이트
    setMemberInfo({ member: null });
  }, [revoke]);

  return { fetchMemberMe, member, login, logout, isMemberLoading };
};
