import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBillsPaymentData, getPaymentReceiptAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { getUserDevice, numberToStringWithComma } from "src/utils/common-util";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 결제 내역 / 연체 내역  > 상세
 */
const ContractPaymentDetail = () => {
  const navigate = usePartnerNavigate();

  const device = getUserDevice();

  // path variable 계약 id, 청구 id
  const { contractId, billId } = useParams();
  // 거래내역 확인서 url
  const { executeAsync: getPaymentReceipt } = useApiOperation(getPaymentReceiptAsync);
  //결제 내역 및 취소네역
  const { executeAsync: fetchPaymentList } = useApiOperation(getBillsPaymentData);

  const location = useLocation();

  const [lastPayment, setLastPayment] = useState<any>({});
  const [payments, setPayments] = useState<any>([]);

  const paymentState: any | null = useMemo(() => {
    // let paymentView: any = payment.payment ? payment.payment : payment;
    if (location.state) {
      return location.state as any;
    }
    return null;
  }, [location]);

  const findS2Payments = useCallback(
    async (billId: string, pgcode: string) => {
      const response = await fetchPaymentList({ billId, pgcode });
      if (response.data.data.content && response.data.data.content.length > 0) {
        let sort = _.sortBy(response.data.data.content, "transactionDate").reverse();
        setLastPayment(sort[0]);
        setPayments(sort);
      }
    },
    [fetchPaymentList],
  );

  // 거래내역 확인서
  const fetchPaymentReciptUrl = async (payment: any) => {
    const { data } = await getPaymentReceipt({ id: String(payment.paymentId) });
    const url = data.data.receipt_url;

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openInAppBrowser",
          data: {
            event: url,
          },
        }),
      );
    } else {
      window.open(url);
      // window.location.href = url;
    }
  };

  useEffect(() => {
    findS2Payments(paymentState.billId, paymentState.pgcode);
  }, [findS2Payments, paymentState]);

  return (
    <>
      <MetaTag title="마이페이지 | TaapSpace" />
      <Header
        headerType="CLOSE"
        title="결제 내역 상세"
        onClickCloseButton={() => {
          console.log("onClickCloseButton");
          navigate(-1);
        }}
      />

      <div className="payment-page">
        <article>
          <section className="payment-main">
            <h1>{/* TODO: paymentLabel 로 표기하는게 맞는지 ? */}</h1>
            <div className="contents-card mb30">
              <div className="contents-card__title-area">
                <p>{moment(lastPayment?.transactionDate).locale("ko").format("YYYY.MM.DD(ddd)")}</p>

                <div className={`base-chip ${lastPayment?.paymentCancel && "color-gray"}`}>
                  <span>{!lastPayment?.paymentCancel ? "결제완료" : "결제취소"}</span>
                </div>
              </div>
              <div className="contents-card__info-area detail">
                <p className="">
                  {lastPayment?.productName}
                  {/* {payment?.paymentLabel} */}
                </p>
                <div className="flex-center">
                  <p>
                    <span className="value">
                      {numberToStringWithComma(
                        Number(!lastPayment?.paymentCancel ? lastPayment?.totalAmount : lastPayment.paymentCancel.cancelAmount || 0),
                      )}
                    </span>{" "}
                    원
                  </p>
                </div>
              </div>
            </div>
          </section>
          {payments.map((payment: any, index: number) => {
            return (
              <section className="base-section-layout" key={index}>
                <div className="base-section-layout__container">
                  <div className="base-section-layout__wrap">
                    <div className="base-section-layout__title-wrap">
                      <h2>결제 정보</h2>
                    </div>
                    {/* TODO: 결제번호 */}
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">결제번호</p>
                      <p className="base-section-layout-value">{payment?.tid || "-"}</p>
                    </div>
                    {/* TODO: 결제취소 금액 */}
                    {/* {payment?.paymentStatus === "PAYMENT_CANCEL" && (
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">취소금액</p>
                    <p className="base-section-layout-value">- 0원</p>
                  </div>
                )} */}
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">결제금액</p>
                      <p className="base-section-layout-value">{numberToStringWithComma(Number(payment?.totalAmount || 0))} 원</p>
                    </div>
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">결제일시</p>
                      <p className="base-section-layout-value">{moment(payment?.transactionDate).format("YYYY/MM/DD HH:mm:ss")}</p>
                    </div>
                    {/* 취소일시 */}
                    {payment?.paymentCancel && (
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">취소일시</p>
                        <p className="base-section-layout-value">
                          {payment?.paymentCancel ? moment(payment?.paymentCancel?.cancelDate).format("YYYY/MM/DD HH:mm:ss") : "-"}
                        </p>
                      </div>
                    )}
                  </div>
                  <button
                    className="base-btn"
                    onClick={() => {
                      fetchPaymentReciptUrl(payment);
                    }}
                  >
                    거래 내역 확인서
                  </button>
                </div>
              </section>
            );
          })}

          <section className="px24">
            <p className="mt20 font14 keep-all text-primary4">
              {device === "android"
                ? "결제사 제공 화면으로 연결 됩니다. 디바이스 제공 [뒤로가기] 버튼을 누르시면 현재 화면으로 이동합니다. (pc 환경에서 인쇄 기능 지원)"
                : device === "ios"
                ? "결제사 제공 화면으로 연결 됩니다. 거래 내역 확인서 상단의 [완료] 버튼을 누르시면 현재 화면으로 이동합니다. (pc 환경에서 인쇄 가능 지원)"
                : ""}
            </p>
          </section>
        </article>
      </div>
    </>
  );
};
export default ContractPaymentDetail;
