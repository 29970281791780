import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { postContractApplyAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { getQuotationListAsync, getQuotationListPublicAsync } from "src/api/reservation/reservation-api";
import { QuotationItem } from "src/api/reservation/reservation-type";
import noImage from "src/assets/images/noImage_default.svg";

import { BaseModal } from "src/components";

import useProductFacility from "src/pages/product/hooks/useProductFacility";
import { Modal } from "src/pages/visitor/visitor-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useMemberInfoState } from "src/recoil/member/hook";
import { getUseTimeDiff, numberToStringWithComma, onChangeKoreanDays } from "src/utils/common-util";
import { authRequired } from "./atom";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";

const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

const QuotationDetail = () => {
  const { member } = useMemberInfoState();
  const setAuthRequired = useSetRecoilState(authRequired);
  const { goBack } = useNavigateGoBack();

  const { openErrorModal } = useErrorModal();

  const navigate = usePartnerNavigate();
  const [quotation, setQuotation] = useState<QuotationItem>();
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // (무인증) 공간상품 정보 조회 api
  const { product, facility, building } = useProductFacility({
    productId: String(quotation?.productId || ""),
    facilityId: String(quotation?.buildingCommonFacilityId || ""),
  });

  // 부가세
  const vat = useMemo(() => {
    let _vat;
    const supplyPrice = quotation?.price;

    _vat = supplyPrice && (supplyPrice * 1.1) / 11;
    return Math.round(Number(_vat));
  }, [quotation]);

  // path variable 공간상품 id
  const { quotationNo } = useParams();

  // query parameter
  const { executeAsync: getQuotaionPublic } = useApiOperation(getQuotationListPublicAsync, { noAuthenticationRequired: true });

  //
  const { executeAsync: getQuotaion } = useApiOperation(getQuotationListAsync);

  const { executeAsync: postContractApply } = useApiOperation(postContractApplyAsync, { noHandleError: true });

  useEffect(() => {
    const fetchQuotationDetail = async () => {
      if (!member) {
        const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey!, { action: "court/quotation/list" });
        if (!token) {
          return;
        }

        const { data, status } = await getQuotaionPublic({ quotationNo: String(quotationNo || ""), token });
        if (status >= 200 && status <= 299) {
          const result = data.data.content[0];
          setQuotation(result.quotationItemList[0]);
        }
      } else {
        const { data, status } = await getQuotaion({ quotationNo: String(quotationNo || "") });
        if (status >= 200 && status <= 299) {
          const result = data.data.content[0];
          setQuotation(result.quotationItemList[0]);
        }
      }
    };

    if (quotationNo) {
      fetchQuotationDetail();
    }
  }, [member, quotationNo]);

  useEffect(() => {
    (async () => {
      if (!member) {
        setAuthRequired(true);
      } else {
        if (product?.id && quotation?.quotationId) {
          const { data, status } = await postContractApply({
            productId: Number(product?.id),
            isAgreeTerms: true,
            quotationId: Number(quotation?.quotationId),
          });

          if (status >= 200 && status <= 299) {
            navigate(`/court/mypage/contracts/${data.data.contractId}`, {
              state: "quotation",
            });
          } else {
            const { errorCode, errorMessage, errorData } = data.meta;
            if (errorCode === "eCT217") {
              setAlertModal({
                isOpen: true,
                title: "잘못된 요청입니다.",
                message: "유효하지 않은 페이지입니다",
              });
            } else {
              openErrorModal({ errorCode, errorMessage, errorData, statusCode: status });
            }
          }
        }
      }
    })();
  }, [member, product?.id, quotation]);

  return (
    <>
      {quotation && (
        <>
          <div className="contract-page">
            <article className="" style={{ height: "100%" }}>
              <section className="contract-main">
                <div className="gray-card">
                  <div className="gray-card__product">
                    <img
                      className="minmax60"
                      src={
                        facility?.mediaList && facility?.mediaList.length > 0
                          ? facility.mediaList.find((media) => media.isPrimary)?.url || facility?.mediaList![0].url
                          : noImage
                      }
                      alt="공용공간 대표이미지"
                    />
                    <div className="gray-card__contents">
                      <p className="gray-card__contents-title">{facility?.facilityName}</p>
                      <p className="gray-card__contents-address">{building.buildingName}</p>
                    </div>
                  </div>
                  <div className="gray-card__info">
                    <>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">이용기간</p>
                        <p className="base-section-layout-value">{`${
                          quotation?.startDate && moment(quotation?.startDate).format("YYYY년 MM월 DD일")
                        } ${onChangeKoreanDays(quotation?.endDate)}요일`}</p>
                      </div>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">이용시간</p>
                        <p className="base-section-layout-value">
                          {`${quotation?.startDate && moment(quotation?.startDate).format("HH:mm")} ~
                      ${quotation?.endDate && moment(quotation?.endDate).format("HH:mm")}
                      ${getUseTimeDiff(quotation?.startDate, quotation?.endDate)}`}
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </section>

              {/* 결제 금액 */}
              <section className="base-section-layout top-divider">
                <div className="base-section-layout__container gap20">
                  <div className="base-section-layout__wrap">
                    <div className="base-section-layout__title-wrap">
                      <h2>결제 금액</h2>
                    </div>
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">상품금액</p>
                      <p className="base-section-layout-value">{numberToStringWithComma(Number(quotation?.price))}원</p>
                    </div>
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">부가세</p>
                      <p className="base-section-layout-value">{numberToStringWithComma(vat)}원</p>
                    </div>
                    <div className="base-section-layout__wrap">
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">총 결제 금액</p>
                        <p className="base-section-layout-value font-weight-600">{numberToStringWithComma(Number(quotation?.price) + vat)}원</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </div>
          <section className="base-section-layout">
            <div className="base-section-layout__wrap">
              {/* <div className="base-section-layout-line">
                <BaseButton title="예약하기" onClick={onSubmit} disabled={!isAgree} />
              </div> */}
              <div className="loader"></div>
            </div>
          </section>
          {alertModal.isOpen && (
            <BaseModal isOpen={true} title={""} onClose={() => goBack()}>
              <div>
                <h2 className="mb20">{alertModal?.title}</h2>
                <p className="pre-formatted text-primary3" style={{ lineHeight: "1.5" }}>
                  {alertModal?.message}
                </p>
                <div className="flex-center-center mt20">
                  <button type="button" className="base-btn" style={{ width: 70, height: 40 }} onClick={() => goBack()}>
                    확인
                  </button>
                </div>
              </div>
            </BaseModal>
          )}
        </>
      )}
    </>
  );
};
export default QuotationDetail;
