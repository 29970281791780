import moment from "moment";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { getNoticeAsync, getPublicNoticeDetailAsync } from "src/api/notice/notice-api";
import { Notice, noticeCategoryToText } from "src/api/notice/notice-types";
import { MarkdownViewer } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import usePostMessage from "src/pages/hooks/post-message";
import { useMemberInfoState } from "src/recoil/member/hook";

/**
 * 공지사항 상세 Taap 웹뷰 화면
 */

type QueryParams = {
  idToken?: string;
  page?: string;
};

const NoticeDetail = () => {
  const initializedRef = useRef(false);
  const { member } = useMemberInfoState();
  const location = useLocation();
  const navigate = usePartnerNavigate();

  const { goBack } = useNavigateGoBack();

  // path variable 공지사항 id
  const { noticeId } = useParams();

  // 공지사항 (인증)목록 조회 api
  const { executeAsync: getNotice } = useApiOperation(getNoticeAsync);
  // 공지사항 (무인증) 상세 조회
  const { executeAsync: getPublicNoticeDetail } = useApiOperation(getPublicNoticeDetailAsync, { noAuthenticationRequired: true });
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true });

  // 공지사항 상세
  const [notice, setNotice] = useState<Notice>();
  const [building, setBuilding] = useState<BuildingModel>();
  const [delay, setDelay] = useState(false);
  const { postMessageGoNoticeListScreen, postMessageGoHomeOrMy } = usePostMessage();
  // queryParam
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location.search]);

  useEffect(() => {
    // member 상태가 변경되면 300ms 후에 QuotationDetail을 보이도록 설정
    const timeoutId = setTimeout(() => {
      setDelay(true);
    }, 500);

    return () => clearTimeout(timeoutId); // 컴포넌트 언마운트 시 clearTimeout을 통해 타이머 정리
  }, [member]);

  useEffect(() => {
    const fetchApi = async (noticeId: string) => {
      const { data } = await getNotice({ noticeId });
      const noticeDetail = data?.data?.notice;
      setNotice(noticeDetail);

      if (noticeDetail && noticeDetail.buildings.length > 0) {
        const buildingId = Number(noticeDetail.buildings[0].buildingId);
        const { data: buildingData } = await getBuildingsDetails({
          buildingIds: [buildingId],
        });
        setBuilding(buildingData.data.content[0].building);
      }
    };
    const fetchPublicApi = async (noticeId: string) => {
      const { data } = await getPublicNoticeDetail({ noticeId });
      const noticeDetail = data?.data?.notice;
      setNotice(noticeDetail);
      if (noticeDetail && noticeDetail.buildings.length > 0) {
        const buildingId = Number(noticeDetail.buildings[0].buildingId);
        const { data: buildingData } = await getBuildingsDetails({
          buildingIds: [buildingId],
        });
        setBuilding(buildingData.data.content[0].building);
      }
    };

    if (delay) {
      // if (window.ReactNativeWebView && noticeId) {
      //   fetchApi(noticeId);
      // } else if (member && noticeId) {
      //   fetchApi(noticeId);
      // } else if (!member && noticeId) {
      //   fetchPublicApi(noticeId);
      // }

      if (window.ReactNativeWebView && noticeId) {
        fetchApi(noticeId);
      } else if (noticeId && queryParams.page === "public") {
        fetchPublicApi(String(noticeId || ""));
      } else if (noticeId) {
        fetchApi(noticeId);
      }
    }
    if (!initializedRef.current) {
      initializedRef.current = true;
    }
  }, [noticeId, getBuildingsDetails, getNotice, getPublicNoticeDetail, queryParams, member, delay]);
  return (
    <div>
      <MetaTag title="공지사항 | TaapSpace" />
      <Header
        headerType="BACK"
        rightArea={
          <button
            type="button"
            className="header-text-btn"
            onClick={() =>
              window.ReactNativeWebView
                ? postMessageGoNoticeListScreen()
                : queryParams.page === "public"
                ? navigate("/court/notices?page=public")
                : navigate("/court/notices")
            }
          >
            목록
          </button>
        }
        onClickCloseButton={() => (window.ReactNativeWebView ? postMessageGoHomeOrMy() : navigate("/court/main"))}
      />
      {initializedRef.current && notice && (
        <div className="base-detail-layout">
          <div className={`title-wrap`}>
            <h2>{notice?.title}</h2>
            <p className="mt2 font14 text-primary3 ellipsis no-wrap lh-20">
              <span className="text-primary4">{moment(notice?.createdDate).format("YYYY.MM.DD")}</span>
              {/* 종류 */}
              {notice?.noticeCategory && noticeCategoryToText(notice?.noticeCategory) ? (
                <>
                  <span className="mx8 text-primary5">|</span>
                  <span className="text-primary2">{noticeCategoryToText(notice?.noticeCategory)}</span>
                </>
              ) : (
                ""
              )}
              {/* 건물명*/}
              {building?.buildingName ? (
                <>
                  <span className="mx8 text-primary5">|</span>
                  <span className="text-primary2">{building?.buildingName}</span>
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <section className="contents__section">
            <div className="contents__item-content">
              <MarkdownViewer value={notice?.content} />
            </div>
          </section>
        </div>
      )}
    </div>
  );
};
export default NoticeDetail;
