import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { routeList, partnerRouteList } from "src/routes/routes";

/**
 * 인증을 거치지 않는 anonymous route path (무인증 페이지들)를 관리하는 hook
 */
export function useAnonymousPathPatterns() {
  const location = useLocation();

  //파트너 관련 라우트 추가 작업
  const routes = [...routeList, ...partnerRouteList];

  // 인증을 거치지 않는 anonymous route path 와 일치할 경우 true
  const matchAnonymous = useMemo(() => {
    // 관리하고 있는 routeList 목록에 존재하는지 확인 후 존재 하지 않을 경우 무인증 페이지로 처리
    const isExistRoute = !!matchRoutes(routes, location);
    if (!isExistRoute) return true;

    // 인증을 거치지 않는 ProtectedRoute 컴포넌트로 wrapping 되어있지 않은 route 들
    const anonymousRouteList = routes.filter((route) => !route?.isProtectRoute);
    const isAnonymousRoute = !!matchRoutes(anonymousRouteList, location);
    return !!isAnonymousRoute;
  }, [location]);

  return { matchAnonymous };
}
