import { Navigate, useLocation } from "react-router-dom";

export default function Unauthenticated() {
  let path = "/court/login";
  const location = useLocation();
  const pathname = location.pathname;
  const pathnameArray = pathname.split("/");

  //배열로 만들고 배열안에 "partner"가 있는지 확인
  const partnerIndex = pathnameArray.findIndex((item) => item === "partner");
  //partner가 있으면 그 뒤에 있는 값을 가져오고 없으면 빈 문자열 반환
  const partnerCode = partnerIndex !== -1 ? pathnameArray[partnerIndex + 1] : "";

  // to = http://localhost:3100/front/court/main
  // newto = http://localhost:3100/front/court/partner/${partnerCode}/main

  if (partnerCode) {
    // newto 는 to 를 배열로 만들고 "court" 뒤에 partner/${partnerCode} 를 삽입해줘
    const newToArr = path.split("/");
    newToArr.splice(2, 0, `partner/${partnerCode}`);
    path = newToArr.join("/");
  }
  return <Navigate replace to={path} />;
}
