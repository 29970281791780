import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";
import QuotationDetail from "./QuotationDetail";
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CourtAuthContext from "src/components/court-auth/contexts/CourtAuthContext";
import { useAnonymousPathPatterns } from "src/pages/hooks/anonymous-path-patterns";
import Link from "src/components/PartnerLink";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";

/**
 * 이용신청 화면
 */
const ContractApply = () => {
  const { member } = useMemberInfoState();

  const [delay, setDelay] = useState(false);
  const naviage = usePartnerNavigate();
  const location = useLocation();

  const { matchAnonymous } = useAnonymousPathPatterns();
  const { security } = useContext(CourtAuthContext);

  const isRequireJoin = useMemo(() => {
    // 인증 페이지 (회원가입 페이지 제외) 접근시 회원가입 해야되는 회원인지 확인 (휴대폰 인증 후 회원 가입 완료 전 상태)
    return (
      !matchAnonymous &&
      location.pathname !== "/court/join/email" &&
      security.status === "authenticated" &&
      security.user?.roles.includes("ROLE_STARTER")
    );
  }, [matchAnonymous, location, security]);

  useEffect(() => {
    if (isRequireJoin) {
      naviage("/court/join/email");
    } else {
      // member 상태가 변경되면 300ms 후에 QuotationDetail을 보이도록 설정
      const timeoutId = setTimeout(() => {
        setDelay(true);
      }, 300);
      return () => clearTimeout(timeoutId); // 컴포넌트 언마운트 시 clearTimeout을 통해 타이머 정리
    }
  }, [isRequireJoin, member]);
  return (
    <>
      <MetaTag title="이용신청 | TaapSpace" isReCAPTCHA={!member ? true : false} />
      <Header headerType="BACK" title={"예약하기"} rightArea={<Link to="/court/main" className="home"></Link>} />
      {delay && <QuotationDetail />}
    </>
  );
};

export default ContractApply;
