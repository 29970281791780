import { ScheduleRecentBill } from "src/api/contract/contract-types";
import { DocumentProofRequest, PaymentInfo, ReceiptBundle } from "./types";
import { CreditCardDetails, VirtualAccountDetails } from "./paymentDetails";

interface ServiceTypePaymentPriceInfoProps {
  recentBill: ScheduleRecentBill;
  checkExpireFiltered: PaymentInfo[];
  receiptBundle?: ReceiptBundle;
  documentProofRequest: DocumentProofRequest;
  onCopyAccount: (account: string) => void;
}

const ServiceTypePaymentPriceInfo = ({
  recentBill,
  checkExpireFiltered,
  receiptBundle,
  documentProofRequest,
  onCopyAccount,
}: ServiceTypePaymentPriceInfoProps) => {
  let payment: any = {};
  if (checkExpireFiltered.length > 0) {
    payment = checkExpireFiltered[0];
  } else {
    payment = recentBill;
  }
  return (
    <div className="base-section-layout__wrap mb30 pb0">
      <div className="base-section-layout__title-wrap">
        <h2>{"결제 정보"}</h2>
      </div>

      {payment.pgcode === "creditcard" && <CreditCardDetails payment={payment} receiptBundle={receiptBundle} />}

      {payment.pgcode === "virtualaccount" && (
        <VirtualAccountDetails
          payment={payment}
          documentProofRequest={documentProofRequest}
          billPayStatus={recentBill.billPayStatus}
          receiptBundle={receiptBundle}
          onCopyAccount={onCopyAccount}
        />
      )}
    </div>
  );
};

export default ServiceTypePaymentPriceInfo;
