import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getContractsAsync } from "src/api/contract/contract-api";
import { MemberContractModel, changeStatusText, getContractStepText } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { MbMemberState } from "src/api/member/member-types";
import BottomLinkList from "src/components/BottomLinkList";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";
import ContractUseProgressProductView from "./components/ContractUseProgressProductView";
import { getProductAsync } from "src/api/product/product-api";
import _ from "lodash";
import Footer from "src/components/layout/Footer";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { getResizedImageUrl } from "src/utils/common-util";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
/**
 * 마이패이지 메인 화면
 */
const MyPageMain = () => {
  const initializedRef = useRef(false);

  const navigate = usePartnerNavigate();

  // 로그인한 회원 정보
  const { member } = useMemberInfoState();

  // (무인증) 공통 미디어파일 목록 api
  const { executeAsync: getPublicMediaFiles } = useApiOperation(getPublicMediaFilesAsync, { noAuthenticationRequired: true, noHandleError: true });

  // 신청/계약 목록 조회 api
  const { executeAsync: getContracts } = useApiOperation(getContractsAsync);
  // 이용중인 공간 표시를 위한 상품 목록 조회 api
  const { executeAsync: getProducts } = useApiOperation(getProductAsync);

  // 신청/계약 목록
  const [contracts, setContracts] = useState<Array<MemberContractModel>>([]);

  const [viewProducts, setViewProduct] = useState<any>([]);

  const findProduct = useCallback(
    async (productId: string) => {
      const response = await getProducts({ productId });

      return response.data.data.content;
    },
    [getProducts],
  );

  // 공통 미디어파일 목록 조회
  const fetchPublicMediaFiles = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getPublicMediaFiles({
        serviceId: buildingId,
        mediaServiceTypes: ["MEDIA_BUILDING"], // 건물 외관 ( 건물 대표 카테고리 )
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        const _primaryMediaFile = data.data.content.find((v: MediaFile) => v.isPrimary === true);
        if (_primaryMediaFile) {
          return _primaryMediaFile;
        }
      }
    },
    [getPublicMediaFiles],
  );

  // 신청/계약 목록 조회
  const fetchContracts = useCallback(
    async (memberNo: string) => {
      try {
        const { data } = await getContracts({
          memberNo,
        });

        if (data?.data?.content) {
          // 이용자 상태 "삭제"인 것 제외
          const filteredContracts = (data.data.content || []).filter((contract: MemberContractModel) => contract?.memberStatus !== "MEMBER_DELETE");
          // 계약자의 상태가 마스터이거나 계약자인 것만 조회
          const filteredAuthContracts = (filteredContracts || []).filter(
            (contract: MemberContractModel) => (contract.isAdmin || contract.isContractor) && contract?.contractStep !== "APPLY_CANCEL",
          );
          const primaryBuildings: any = [];
          for (let i = 0; i < filteredAuthContracts.length; i++) {
            if (filteredAuthContracts[i].contractStep === "USE_PROGRESS") {
              const getPd: any = await findProduct(String(filteredAuthContracts[i].spaceProductId));

              if (getPd) {
                const buildingWrapper = (getPd.buildingList || []).find((wrapper: any) =>
                  (wrapper?.building?.buildingFloorList || []).some((buildingFloor: any) =>
                    buildingFloor?.buildingRoomList.some((buildingRoom: any) => buildingRoom?.isPrimary),
                  ),
                );
                if (buildingWrapper) {
                  // console.log("buildingWrapper", buildingWrapper);
                  let mediaFile = "";
                  const getPrimaryMedia = await fetchPublicMediaFiles(String(buildingWrapper.id));
                  if (getPrimaryMedia && getPrimaryMedia.url) {
                    mediaFile = getPrimaryMedia!.url;
                  }

                  let pdName = getPd.productName;
                  if (filteredAuthContracts[i].contractName !== "") {
                    pdName = filteredAuthContracts[i].contractName;
                  }

                  let viewDataSet = {
                    id: String(getPd.id) + "_" + String(filteredAuthContracts[i].contractId),
                    productName: pdName,
                    locationCode: buildingWrapper.building.locationCode || "",
                    buildingName: buildingWrapper.building.buildingName,
                    address: buildingWrapper.building?.addressList?.[0]?.address,
                    src: getResizedImageUrl(mediaFile),
                    contractName: filteredAuthContracts[i].contractName,
                  };

                  primaryBuildings.push(viewDataSet);
                }
              }
            }
          }
          setViewProduct(primaryBuildings);
          setContracts(filteredAuthContracts);
        }
      } catch (error) {
        console.error("fetchContracts error", error);
      }

      if (!initializedRef.current) {
        initializedRef.current = true;
      }
    },
    [findProduct, getContracts],
  );

  useEffect(() => {
    if (member && member.memberNo) {
      // 신청/계약 목록 조회
      fetchContracts(member.memberNo);
    }
  }, [member]);

  // 회원 이메인 인증 여부
  const isEmailVerified = useMemo(() => {
    if (!member) return false;
    return !!(member?.states || []).find((state: MbMemberState) => state.status === "EMAIL_VERIFIED");
  }, [member]);

  const changeDurations = (start: string, end: string) => {
    const diff = moment(end).diff(moment(start));
    // console.log(start, end, "diff", diff);
    const duration = moment.duration(diff).asMilliseconds();
    // console.log("duration", duration, moment(duration).hours() - 9, moment(duration).minutes());
    return moment(duration).hours() - 9 !== 0 ? moment(duration).hours() - 9 + "시간 " : "" + moment(duration).minutes() + "분";
  };

  return (
    <>
      <MetaTag title="마이페이지 | TaapSpace" />
      <Header />
      <div className="my-page">
        <article className="after-login-contents bottom-divider">
          <h1>마이페이지</h1>
          <div className="flex-center">
            <div className="profile-img">
              <span>{member?.email && member?.email.charAt(0).toLocaleUpperCase()}</span>
            </div>
            <div className="profile-content">
              <p className={isEmailVerified ? "" : "invalid"}>{member?.email}</p>
              <button type="button" onClick={() => navigate("/court/mypage/profile")}>
                프로필 편집
              </button>
            </div>
          </div>
        </article>
        <ContractUseProgressProductView viewData={viewProducts} />

        <article className="after-login-contract bottom-divider">
          <div className="title-area">
            <span>신청/계약</span>
            {contracts.length > 0 && (
              <button type="button" onClick={() => navigate("/court/mypage/contracts")}>
                더보기
              </button>
            )}
          </div>
          {/* 신청/계약 목록 */}
          {initializedRef.current && (
            <div className={`contents-area ${contracts.length === 0 ? "no-list" : ""}`}>
              {contracts.length > 0 ? (
                contracts.map((contract: MemberContractModel, index: number) => (
                  <div key={index} className={`${contracts.length === 1 ? "w-100" : ""} contents-card`}>
                    <div className="contents-card__title-area">
                      <p>{contract.spaceProductName}</p>
                      <div
                        className={`base-chip ${
                          ["USE_APPROVAL", "USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || "") ? "" : "color-gray"
                        }`}
                      >
                        {changeStatusText(contract?.spaceProductType!, contract?.contractStep!)}
                      </div>
                    </div>
                    {/* F/O 타입: 이용기간, 이용자 현황 표시 */}
                    {contract?.spaceProductType !== "TIME_COURT" && (
                      <>
                        <div className="contents-card__info-area">
                          <p>
                            {contract?.useStartTime && moment(contract?.useStartTime).format("YYYY-MM-DD")} ~{" "}
                            {contract?.useEndTime && moment(contract?.useEndTime).format("YYYY-MM-DD")}
                            <br />
                            이용자 {contract?.memberTotCount || 0} / {contract?.memberMaxNums || 0}명
                          </p>
                          <button type="button" onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}`)} />
                        </div>
                        <div className="contents-card__info-area"></div>
                      </>
                    )}
                    {/* T 타입: 이용일자, 이용 시간 표시 */}
                    {contract?.spaceProductType === "TIME_COURT" && (
                      <div className="contents-card__info-area">
                        {/* T 타입: 이용일자, 이용 시간 표시 */}
                        <p>
                          이용 일자 : {contract?.useStartTime && moment(contract?.useStartTime).format("YYYY-MM-DD")}
                          <br />
                          이용 시간 : {contract?.useStartTime && moment(contract?.useStartTime).format("HH:mm")} ~{" "}
                          {contract?.useEndTime && moment(contract?.useEndTime).format("HH:mm")}
                          {" ( " +
                            changeDurations(
                              moment(contract?.useStartTime).format("YYYY-MM-DD HH:mm"),
                              moment(contract?.useEndTime).format("YYYY-MM-DD HH:mm"),
                            ) +
                            " )"}
                        </p>
                        <button type="button" onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}`)} />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="contents-card-none">
                  <span>신청/계약 내역이 없어요.</span>
                </div>
              )}
            </div>
          )}
        </article>

        <BottomLinkList />
        <Footer />
      </div>
    </>
  );
};
export default MyPageMain;
