import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { postEmailVerificationsAsync } from "src/api/member/member-api";
import { BaseButton, BaseTextInput, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";

type FormData = {
  email: string;
};

/**
 * 마이페이지 메인 > 프로필 편집 > 이메일 인증 화면
 */
const EmailVerify = () => {
  // 로그인한 회원 정보
  const { member } = useMemberInfoState();

  // 이메일 인증 링크 발송 api
  const { executeAsync: postEmailVerifications, loading: postEmailLoading } = useApiOperation(postEmailVerificationsAsync);

  // 이메일 인증 링크 발송 여부
  const [isCompletedSendEmail, setIsCompletedSendEmail] = useState<boolean>(false);

  const defaultValues = useMemo(() => {
    return {
      email: "",
    };
  }, []);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    register("email", {
      required: "이메일을 입력해주세요",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "올바르지 않은 이메일 형식입니다",
      },
    });
  }, [register]);

  useEffect(() => {
    if (member?.email) {
      setValue("email", member?.email);
    }
  }, [member]);

  // 이메일 인증 발송 완료 모달
  const [isOpenModalSendEmail, setIsOpenModalSendEmail] = useState<boolean>(false);

  // 인증 메일 대기 안내 모달
  const [isOpenModalEmailInfo, setIsOpenModalEmailInfo] = useState<boolean>(false);

  // 이메일 인증 링크 발송
  const emailVerifications = useCallback(
    async ({ email }: FormData) => {
      const { data } = await postEmailVerifications({ email });
      console.log("emailVerifications", data);
      if (data?.data?.emailVerification) {
        setIsOpenModalSendEmail(true);
        setIsCompletedSendEmail(true);
      }
    },
    [postEmailVerifications],
  );

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback((data: FormData, e?: any) => {
    e.preventDefault();
    console.log("onSubmit", data, e);

    // 이메일 인증 링크 발송
    emailVerifications(data);
  }, []);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  };

  // 전화문의 버튼 클릭
  const onClickTel = useCallback(() => {
    window.location.href = "tel:02-3442-6610";
  }, []);

  return (
    <>
      <Header rightDisabled={true} />
      <MetaTag title="마이페이지 | TaapSpace" />

      <div className="my-page">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <article className="email-auth">
            {isCompletedSendEmail ? (
              <>
                <div className="title-wrap">
                  <h1>메일함을 확인해주세요</h1>
                  <p>안전한 이메일 등록을 위해 확인 절차를 완료해주시기 바랍니다.</p>
                </div>
                <div className="login-input-wrap">
                  <input type="text" className="login-input" value={member?.email} readOnly />
                  <button type="button" className="only-text text-green" disabled>
                    전송완료
                  </button>
                  <p>올바르지 않은 이메일 형식입니다</p>
                  <button
                    type="button"
                    className="mail-not-coming-btn"
                    onClick={() => {
                      setIsOpenModalEmailInfo(true);
                    }}
                  >
                    인증 메일이 오지 않나요?
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="title-wrap">
                  <h1>
                    사용중인 이메일을
                    <br />
                    인증해주세요
                  </h1>
                </div>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                    <BaseTextInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="이메일을 입력해주세요"
                      isInvalid={!!error}
                      invalidMessage={error?.message}
                      isCompleted={isDirty && !error}
                    />
                  )}
                ></Controller>
              </>
            )}
            <section className="btn-wrap">
              <BaseButton
                type="submit"
                className="login-btn"
                disabled={!isValid}
                title={isCompletedSendEmail ? "재전송" : "인증하기"}
                isLoading={postEmailLoading}
              />
            </section>
          </article>
        </form>

        {/* 인증 메일 발송 완료 모달 */}
        <ConfirmModal
          isOpen={isOpenModalSendEmail}
          btnRightTitle="확인"
          onClick={() => {
            setIsOpenModalSendEmail(false);
          }}
          children={
            <div>
              <p className="font18 font-weight-600">인증 메일을 발송 요청했어요.</p>
              <p className="font15 font-weight-400 mt20">인증 메일이 발송 요청되었습니다.</p>
              <p className="font15 font-weight-400 mt5">메일을 확인해주세요.</p>
            </div>
          }
        />

        {/* 인증 메일 대기 안내 모달 */}
        <ConfirmModal
          isOpen={isOpenModalEmailInfo}
          btnLeftTitle="고객센터"
          btnRightTitle="확인"
          onClose={() => {
            onClickTel();
          }}
          onClick={() => {
            setIsOpenModalEmailInfo(false);
          }}
          className="px20 pb20"
          children={
            <div className="email-auth-info-modal">
              <p>인증메일이 안 온다면?</p>
              <br />
              <span className="font-weight-600">1. 스팸메일함 확인</span>
              <p>인증메일이 스팸메일함으로 전송되었는지 확인해주세요.</p>
              <br />
              <span className="font-weight-600">2. 재전송하기</span>
              <p>화면 내에 [재전송] 버튼을 눌러 다시 발송요청을 진행해주세요</p>
              <br />
              <p>
                그래도 해결되지 않는다면 고객센터로 문의해주세요.
                <br />
                <span className="text-primary3">(운영시간 - 평일 09:00~18:00)</span>
              </p>
              <br />
            </div>
          }
        />
      </div>
    </>
  );
};
export default EmailVerify;
