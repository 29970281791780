import { useParams } from "react-router-dom";
import { CashReceiptSectionProps } from "../../components/types";
import { checkProgressStatus } from "../utils";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";

export const CashReceiptSection = ({
  recentBill,
  documentProofRequest,
  cashBillList,
  paymentAgree,
  setPaymentAgree,
  proofError,
  setDocumentProofRequest,
  setCashErrorFlags,
  handleClickExpenseProofCashBill,
}: CashReceiptSectionProps) => {
  const { contractId } = useParams();
  const navigate = usePartnerNavigate();

  return (
    <section className="base-section-layout top-divider my-page-cashbill">
      <div className="base-section-layout__container">
        <div className="base-section-layout__wrap mb30 pb0">
          <div className="base-section-layout__title-wrap">
            <h2>현금영수증</h2>
          </div>

          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">발행</p>
            {cashBillList.length > 0 ? (
              cashBillList.map((cash: any, index: number) => (
                <p className="base-section-layout-value" key={index}>
                  {checkProgressStatus(cash.progressStatus)}
                </p>
              ))
            ) : (
              <p className="base-section-layout-value">대기</p>
            )}
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">거래유형</p>
            <p className="base-section-layout-value">
              {documentProofRequest?.tradeUsage === "INCOME"
                ? documentProofRequest?.identityType === "MOBILE_NUM"
                  ? "개인소득공제(휴대폰)"
                  : "개인소득공제(현금영수증카드)"
                : documentProofRequest?.identityType === "MOBILE_NUM"
                ? "사업자증빙용(휴대폰)"
                : documentProofRequest?.identityType === "CORP_NUM"
                ? "사업자증빙용(사업자번호)"
                : "사업자증빙용(현금영수증카드)"}
            </p>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">
              {documentProofRequest?.tradeUsage === "INCOME" ? "휴대폰/카드번호" : "사업자번호/카드번호"}
            </p>
            <p className="base-section-layout-value">{documentProofRequest?.identityNum}</p>
          </div>

          {(cashBillList.length === 0 || cashBillList[0].progressStatus === "TRANS_READY" || cashBillList[0].progressStatus === "TEMP_SAVE") && (
            <>
              <div className="base-floating-btn-wrap px0">
                <button
                  type="button"
                  className="base-btn color-white base-btn-cashbill"
                  onClick={() => {
                    navigate(`/court/mypage/contracts/${contractId}/CashBillInfoEdit`);
                  }}
                >
                  발급 정보 수정
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* <CashReceiptSubmitButton
        paymentAgree={paymentAgree}
        setPaymentAgree={setPaymentAgree}
        documentProofRequest={documentProofRequest}
        handleClickExpenseProofCashBill={handleClickExpenseProofCashBill}
      /> */}
    </section>
  );
};
