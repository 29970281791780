import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { patchJoinAsync } from "src/api/member/member-api";
import { BaseButton, BaseTextInput } from "src/components";
import { useCourtAuth } from "src/components/court-auth";
import courtAuthStateRepository from "src/components/court-auth/utils/court-auth-state-repository";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "../constants";
type FormData = {
  email: string;
};

/**
 * 이메일 등록 화면 (회원가입)
 */
const RegisterEmail = () => {
  const navigate = useNavigate();

  const { refreshTokenAsync } = useCourtAuth();

  console.log("returnToLatest = courtAuthStateRepository.load()?.returnTo?.latest", courtAuthStateRepository.load()?.returnTo?.latest);

  const returnUri = courtAuthStateRepository.load()?.returnTo?.latest?.uri?.replace("/front", "") || "/court/main";

  // 회원가입 api
  const { executeAsync: patchJoin, loading: patchJoinLoading } = useApiOperation(patchJoinAsync);

  const {
    handleSubmit,
    register,
    control,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    register("email", {
      required: "이메일을 입력해주세요",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "올바르지 않은 이메일 형식입니다",
      },
    });
  }, [register]);

  // 회원가입
  const join = useCallback(
    async (data: FormData) => {
      //마케팅 동의 false로 설정
      const { data: patchJoinResultData } = await patchJoin({ email: data.email, marketingConsent: false });
      if (patchJoinResultData?.data?.member) {
        // 회원가입 후 refresh token
        await refreshTokenAsync();
        // 회원가입 완료 페이지로 이동
        console.log("returnUri", returnUri);

        navigate(returnUri);
      }
    },
    [patchJoin, refreshTokenAsync, navigate, returnUri],
  );

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    (data: FormData, e?: any) => {
      e.preventDefault();

      console.log("onSubmit", data, e);

      // 회원가입
      join(data);
    },
    [join],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  };

  return (
    <>
      <Header headerType={"BACK"} />
      <div className="my-page">
        <MetaTag title="회원가입 | TaapSpace" />

        <article className="container">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="title-wrap">
              <h5>이메일을 입력</h5>
              <p className="description">
                휴대폰 번호 변경 시 본인 확인을 위해 이메일을 입력해 주세요.
                <br /> 기업 사용자는 기업 이메일을 입력해 주세요
              </p>
            </div>

            {/* 약관동의 */}

            <Controller
              control={control}
              name="email"
              render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                <BaseTextInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="이메일을 입력해주세요"
                  isInvalid={!!error}
                  invalidMessage={error?.message}
                  isCompleted={isDirty && !error}
                />
              )}
            ></Controller>

            <p className="agree">
              <a target="_blank" rel="noreferrer noopener" href={TERMS_OF_SERVICE_URL}>
                서비스 이용약관
              </a>
              [필수]과{" "}
              <a target="_blank" rel="noreferrer noopener" href={PRIVACY_POLICY_URL}>
                개인정보 수집 및 이용
              </a>
              [필수]에 동의합니다.
            </p>

            <BaseButton type="submit" className="login-btn" disabled={!isDirty || !isValid} title="동의하고 시작하기" isLoading={patchJoinLoading} />
          </form>
        </article>
      </div>
    </>
  );
};
export default RegisterEmail;
