import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ProductGuideDetail } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { updateExternalVisitorAsync } from "src/api/visitor/visitor-api";
import { Visitor } from "src/api/visitor/visitor-types";
import { ConfirmModal, MarkdownViewer } from "src/components";
import { useToast } from "src/pages/hooks/toast";
import { Modal } from "../visitor-types";
import Link from "src/components/PartnerLink";

type Props = {
  visitor?: Visitor;
  fetchVisitor: (visitorKey: string) => void;
  productGuides?: ProductGuideDetail[];
  isPreview?: boolean; // 방문자초대 "미리보기" 화면인지
};

type FormData = {
  visitorCarNumber: string; // 차량번호
};

/**
 * 방문자 초대 상세 웹뷰 화면 > 주차 안내
 */
const ParkingGuide = ({ visitor, fetchVisitor, productGuides, isPreview }: Props) => {
  const params = useParams();

  const { openToast } = useToast();

  // (무인증)방문자 등록차량 정보 수정 api
  const { executeAsync: updateVisitor, loading: updateVisitorLoading } = useApiOperation(updateExternalVisitorAsync, {
    noAuthenticationRequired: true,
  });

  // 차량번호 변경 화면 여부
  const [isEditingCarNumber, setIsEditingCarNumber] = useState<boolean>(false);

  // 확인 취소 버튼 있는 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal<FormData>>({ isOpen: false });

  // 주차안내
  const parkingGuide = useMemo(() => {
    if (!productGuides || productGuides.length === 0) return undefined;
    return productGuides.find((guide) => guide.isDisplayed === true && guide.productGuideType === "PRODUCT_GUIDE_PARKINGLOT2");
  }, [productGuides]);

  const defaultValues = useMemo(() => {
    return {
      visitorCarNumber: visitor?.visitorCarNumber || "",
    };
  }, [visitor]);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  useEffect(() => {
    if (visitor?.visitorCarNumber) {
      setValue("visitorCarNumber", visitor.visitorCarNumber);
    }
    console.log(visitor);
  }, [visitor, setValue]);

  // 방문자 등록차량 정보 저장
  const saveVisitorCarNumber = useCallback(
    async ({ visitorCarNumber }: FormData) => {
      try {
        if (!params.visitorKey) throw Error("params.visitorKey is not found");
        const { data } = await updateVisitor({ visitorKey: params.visitorKey, visitorCarNumber });
        if (data?.data?.contractVisitorId) {
          openToast({ content: "차량번호가 등록되었습니다" });
          await fetchVisitor(params.visitorKey);
          setIsEditingCarNumber(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [params, updateVisitor, fetchVisitor, openToast],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (confirmModal.type === "PARKING_SAVE") {
      // 회의실 저장
      if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
      await saveVisitorCarNumber(confirmModal.payload);
    }
  }, [confirmModal, saveVisitorCarNumber]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: FormData, e?: any) => {
    e.preventDefault();

    setConfirmModal({
      message: "저장하시겠습니까?",
      isOpen: true,
      type: "PARKING_SAVE",
      payload: data,
    });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    return false;
  };

  return (
    <>
      <section className={"base-accordion no-icon pb40"}>
        <div className="base-accordion__title-area">
          <span>주차 안내</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="base-accordion__contents">
            <div className="pt10">
              {/* TODO: 주차 연동 기능이 아직없기 때문에 주석처리. 이후 central 주차 연동 기능 개발 완료되면 주석 해제 */}
              {/* {isEditingCarNumber ? (
                  <div className={`visitor-input-wrap mb20 ${errors.visitorCarNumber && "--invalid"}`}>
                    <input
                      type="text"
                      className="login-input"
                      placeholder="차량번호를 입력하세요"
                      maxLength={8}
                      disabled={updateVisitorLoading}
                      {...register("visitorCarNumber", {
                        required: "차량번호를 입력해주세요.",
                        pattern: {
                          value: /^\d{2,3}[가-힣]{1}\d{4}$/,
                          message: "올바르지 않은 차량 번호입니다.",
                        },
                      })}
                    />
                    <button type="submit" className="only-text" disabled={updateVisitorLoading}>
                      저장
                    </button>
                    {errors.visitorCarNumber && <p>{errors.visitorCarNumber.message}</p>}
                  </div>
                ) : (
                  <div className="like-disabled-input mb20">
                    <div className="like-disabled-input__prefix">
                      <span>등록된 차량</span>
                    </div>
                    {visitor?.visitorCarNumber && (
                      <div className="like-disabled-input__value">
                        <span>{visitor?.visitorCarNumber}</span>
                      </div>
                    )}
                    <div className="like-disabled-input__suffix">
                      <button className="text-underline" onClick={() => setIsEditingCarNumber(true)}>
                        {visitor?.visitorCarNumber ? "변경" : "등록"}
                      </button>
                    </div>
                  </div>
                )} */}
              {parkingGuide?.description ? (
                <div className="mt0">
                  <MarkdownViewer value={parkingGuide.description} />
                </div>
              ) : (
                <div className="mt0">
                  <ul className="">
                    <li>자동 주차지원이 되지 않는 곳입니다.</li>
                    <li>주차 지원을 받기 위해서는 별도로 초대한 담당자에게 말씀하셔야합니다.</li>
                  </ul>
                </div>
              )}
              {visitor?.isKpsFeatureOn && (
                <div className="parking-number-box mt20">
                  <div>
                    <p className="car-number">{visitor?.visitorCarNumber ? visitor?.visitorCarNumber : "차량 번호를 입력해 주세요"}</p>
                  </div>
                  {isPreview ? (
                    <button className="only-text-btn ic-chevron-right" type="button">
                      {visitor?.visitorCarNumber ? "수정하기" : "등록하기"}
                    </button>
                  ) : (
                    <Link to={`/court/visitor/changeCarNumber/${params.visitorKey}`}>
                      <button className="only-text-btn ic-chevron-right" type="button">
                        {visitor?.visitorCarNumber ? "수정하기" : "등록하기"}
                      </button>
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </form>

        <ConfirmModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => clickModalConfirm()}
        >
          {confirmModal.message || ""}
        </ConfirmModal>
      </section>
    </>
  );
};
export default ParkingGuide;
