export type Modal<T = {}> = {
  isOpen: boolean;
  title?: string;
  message?: string;
  type?: string;
  payload?: T;
};

export type Purpose = {
  label: string;
  value: string;
};

export const ConsentAgree = {
  DENIED: "DENIED", // 비동의
  GRANTED: "GRANTED", // 동의
} as const;

export const VisitType = {
  SELF_REGIST: "SELF_REGIST", //셀프등록
  INVITE_REGIST: "INVITE_REGIST", //초대
} as const;

export const purposeSelectOptions: Purpose[] = [
  {
    label: "투어/상담",
    value: "TOUR_COUNSELING",
  },
  {
    label: "지인방문",
    value: "FRIEND_VISIT",
  },
  { label: "택배", value: "PACKAGE_DELIVERY" },
  { label: "우편", value: "MAIL_DELIVERY" },
  { label: "배달", value: "ETC_DELIVERY" },
];

export const getPurposeLabel = (purpose: string) => {
  switch (purpose) {
    case "TOUR_COUNSELING":
      return "투어/상담";
    case "FRIEND_VISIT":
      return "지인방문";
    case "PACKAGE_DELIVERY":
      return "택배";
    case "MAIL_DELIVERY":
      return "우편";
    case "ETC_DELIVERY":
      return "배달";
    default:
      return "";
  }
};
