import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { getProviderAccountsAsync } from "src/api/contract/contract-api";
import { BillingContractBillModel, BillModel, ContractModel, CtProviderAccount, isFreeContract } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { useToast } from "src/pages/hooks/toast";
import { numberToStringWithComma } from "src/utils/common-util";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";

type Props = {
  contract?: ContractModel | null;
  billingContractBill?: BillingContractBillModel | null;
};
/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 화면 > 계좌 정보 (F/O 타입 이고 / 계약체결 / 유료일때 출력)
 */
const AccountInfo = ({ contract, billingContractBill }: Props) => {
  // 계약 프로바이더 입금계좌 조회 api
  const { executeAsync: getProviderAccounts } = useApiOperation(getProviderAccountsAsync);

  // 토스트
  const { openToast } = useToast();

  // 계약 프로바이더 입금계좌 목록
  const [providerAccounts, setProviderAccounts] = useState<CtProviderAccount[]>([]);

  // 무료 여부
  const isFree = useMemo(() => {
    return isFreeContract(contract);
  }, [contract]);

  // 보증금 계좌 목록
  const depositAccounts = useMemo(() => {
    if (!providerAccounts || providerAccounts.length === 0) return [];
    return providerAccounts.filter((account) => account.accountType === "DEPOSIT_ACCOUNT");
  }, [providerAccounts]);

  // 보증금 (1회차 원청구) 청구서
  const depositBill = useMemo(() => {
    if (!billingContractBill || (billingContractBill?.billList || []).length === 0) return null;
    return (billingContractBill?.billList || []).find((bill: BillModel) => bill.billOrder === 1 && !bill?.originBill.isOverdueBill);
  }, [billingContractBill]);

  // 계약 프로바이더 입금계좌 조회
  const fetchProviderAccounts = useCallback(
    async (contractApplyNumber: string) => {
      const { data } = await getProviderAccounts({
        contractApplyNumber,
        accountType: "DEPOSIT_ACCOUNT", // 보증금 계좌
      });
      setProviderAccounts(data?.data?.content || []);
    },
    [getProviderAccounts],
  );

  const isContractAccpeted = (contractStep: string) =>
    ["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE", "TERMINATE_RECEIVED", "TERMINATE_COMPLETE"].includes(contractStep);

  useEffect(() => {
    if (contract?.contractApplyNumber) {
      // 계약 프로바이더 입금계좌 조회
      fetchProviderAccounts(contract.contractApplyNumber);
    }
  }, [contract, fetchProviderAccounts]);

  if (contract?.spaceProductType !== "TIME_COURT" && isContractAccpeted(contract?.contractStep || "") && !isFree) {
    return (
      <section className="base-section-layout top-divider">
        <div className="base-section-layout__container gap20">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <h2>보증금 납부 정보</h2>
            </div>
            {/* 보증금(잔금) */}
            <div className="base-section-layout-line">
              <p className="base-section-layout-index text-primary3">보증금</p>
              <p className="base-section-layout-value">
                <b>{contract?.balanceAmount ? numberToStringWithComma(contract?.balanceAmount) : "-"}</b> 원
              </p>
            </div>
            {/* 1회차 청구일자 = 보증금 납기일 */}
            <div className="base-section-layout-line">
              <p className="base-section-layout-index text-primary3">납기일</p>
              <p className="base-section-layout-value">
                {depositBill?.originBill.billIssueDate ? moment(depositBill?.originBill.billIssueDate).format("YYYY년 MM월 DD일") : "-"} 까지
              </p>
            </div>
          </div>
          <div className="base-section-layout__wrap">
            {depositAccounts.map((account: CtProviderAccount, index: number) => (
              <div key={index.toString()} className="base-section-layout-line">
                <div className="base-section-layout-index flex-center w-100">
                  <div className="mr10 no-wrap">
                    <span>{account.bankName}</span>
                  </div>
                  <CopyToClipboard text={`${account.bankName} ${account.accountNumber}`}>
                    <div
                      className="mr10 font-family-roboto text-underline cursor-pointer"
                      onClick={() => {
                        openToast({ content: "계좌정보가 복사되었습니다" });
                      }}
                    >
                      <span>{account.accountNumber}</span>
                    </div>
                  </CopyToClipboard>
                  <div className="w-100 ellipsis">
                    <span>{account.accountHolder}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default AccountInfo;
