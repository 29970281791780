import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiResponse } from "../public-types";
import {
  AccountType,
  AutoPayResponse,
  BillingContractBillModel,
  CalculateAmountModel,
  ContarctApplyResponse,
  ContractAddModel,
  ContractAddResponseData,
  ContractApplyRequest,
  ContractHancomSpaceResponse,
  ContractModel,
  ContractPaymentModel,
  ContractTimePaymentResponseData,
  ContractUpdateResponseData,
  CreateContractHancomSpaceResponse,
  CtProviderAccount,
  MemberContractManageListParams,
  MemberContractManageModel,
  MemberContractModel,
} from "./contract-types";
import _ from "lodash";

/**
 * TCT34. T타입 청구/결제
 */
export async function postContractTimePaymentAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
  },
): Promise<AxiosResponse<ApiResponse<ContractTimePaymentResponseData>>> {
  if (!params?.contractId) {
    throw Error("params.contractId is not found.");
  }
  return await axios.post(`/api/court/ct/contract/${params.contractId}/time-payment`);
}

/**
 * TCT31. 신청/계약 상세
 */
export async function getContractAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
  },
): Promise<AxiosResponse<ApiResponse<ContractModel>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }
  return await axios.get(`/api/court/ct/contract/${params.contractId}`);
}

/**
 * TCT30. 신청/계약 목록
 */
export async function getContractsAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<MemberContractModel> }>>> {
  if (!params?.memberNo) {
    throw Error("memberNo is not found.");
  }
  return await axios.get(`/api/court/ct/contract/member/${params.memberNo}`);
}

/**
 * TCT33. 이용신청 취소
 */
export async function patchContractCancelAsync(
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractUpdateResponseData>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }
  return await axios.patch(`/api/court/ct/contract/${params.contractId}/cancel`, {
    contract: {
      contractStep: "APPLY_CANCEL", // 신청취소
    },
  });
}

/**
 * TCT36. 계약 첨부파일 다운로드
 */
export async function getContractMediaContentAsync(
  axios: AxiosInstance,
  params?: {
    contractId: number;
    mediaId: number;
  },
): Promise<AxiosResponse<any>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }
  if (!params?.mediaId) {
    throw Error("mediaId is not found.");
  }

  return await axios.get(`/api/court/ct/contract/${params.contractId}/media/${params.mediaId}/content`, { responseType: "blob" });
}

/**
 * TCT35. 신청/계약 결제내역
 */

export const postContractApplyAsync = async (
  axios: AxiosInstance,
  data?: ContractApplyRequest,
): Promise<AxiosResponse<ApiResponse<ContarctApplyResponse>>> => {
  return await axios.post("/api/court/ct/contract/apply", data);
};

// export async function getContractBillsPaymentAsync(
//   axios: AxiosInstance,
//   params?: {
//     contractId: string;
//   },
// ): Promise<AxiosResponse<ApiResponse<{ content: Array<ContractPaymentModel> }>>> {
//   if (!params?.contractId) {
//     throw Error("contractId is not found.");
//   }
//   return await axios.get(`/api/court/ct/contract/${params.contractId}/bills/payment`);
// }

/**
 * TCT36. 신청/계약 연체내역
 */
export async function getContractBillsOverdueAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<ContractPaymentModel> }>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }
  return await axios.get(`/api/court/ct/contract/${params.contractId}/bills/overdue`);
}

/**
 * TCT32. 이용신청 등록
 */
export async function postContractAsync(
  axios: AxiosInstance,
  params?: {
    contract: ContractAddModel;
  },
): Promise<AxiosResponse<ApiResponse<ContractAddResponseData>>> {
  if (!params?.contract) {
    throw Error("params.contract is not found.");
  }
  return await axios.post("/api/court/ct/contract", params.contract);
}

/**
 * TCT38. 결제/취소 내역 조회
 */
export async function getContractPaymentsAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    isOverdueBill: boolean;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<ContractPaymentModel> }>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }
  return await axios.get(`/api/court/ct/contract/${params.contractId}/payment`, {
    params: {
      isOverdueBill: params.isOverdueBill,
    },
  });
}

/**
 * TCT39. 결제금액 산출(T타입 전용)
 */
export async function getCalculateAmountAsync(
  axios: AxiosInstance,
  params?: {
    productId: string;
    useStartTime: string;
    useEndTime: string;
  },
): Promise<AxiosResponse<ApiResponse<{ calculateAmount: CalculateAmountModel }>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  if (!params?.useStartTime) {
    throw Error("useStartTime is not found.");
  }
  if (!params?.useEndTime) {
    throw Error("useEndTime is not found.");
  }
  return await axios.get(`/api/court/ct/product/${params.productId}/calculate-amount`, {
    params: {
      useStartTime: params.useStartTime,
      useEndTime: params.useEndTime,
    },
  });
}

/**
 * TCT42. 이용자 초대
 */
export async function postMemberContractManageInviteAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: number; // 계약 관리 id
    contractMemberList: Array<{ inviteMobileNumber: string }>;
  },
): Promise<AxiosResponse<ApiResponse<{ contractMemberId: Array<string> }>>> {
  if (!params?.contractManageId) {
    throw Error("params.contractManageId is not found.");
  }
  if ((params?.contractMemberList || []).length === 0) {
    throw Error("params.contractMemberList is not found.");
  }
  return await axios.post(`/api/court/ct/member/contract-manage/${params.contractManageId}/invite`, {
    contractMemberList: params.contractMemberList,
  });
}

/**
 * TCT43. 이용자 삭제
 */
export async function patchMemberContractManageDeleteAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: string | number; // 계약 관리 id
    contractMemberId: string | number; // 이용자 id
    isDeleted: boolean; // 삭제여부
  },
): Promise<AxiosResponse<ApiResponse<{ contractMemberId: number }>>> {
  if (!params?.contractManageId) {
    throw Error("params.contractManageId is not found.");
  }
  if (!params?.contractMemberId) {
    throw Error("params.contractMemberId is not found.");
  }
  return await axios.patch(`/api/court/ct/member/contract-manage/${params.contractManageId}/delete`, {
    contractMember: {
      contractMemberId: params.contractMemberId,
      isDeleted: params.isDeleted,
    },
  });
}

/**
 * TCT44. 로그인 사용자 계약 목록 조회
 */
export async function getMemberAssignContractsAsync(
  axios: AxiosInstance,
  params?: {
    isContractor?: boolean; // 계약자로 등록된 계약목록만 조회
    isInProgress?: boolean; // 계약 이용중 여부 (TRUE : 이용 중인 계약목록 조회(default), FALSE : 이용대기 중인 계약목록 조회)
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<MemberContractModel> }>>> {
  let queryParams = {};
  if (params?.isContractor) {
    queryParams = { ...queryParams, ...{ isContractor: true } };
  }
  if (params?.isInProgress) {
    queryParams = { ...queryParams, ...{ isInProgress: true } };
  }
  return await axios.get("/api/court/ct/member/assign/my-contract", {
    params: queryParams,
  });
}

/**
 * TCT45. 이용 중인 공간 (내가 이용자로 초대되어 있는 계약 목록) 조회
 */
export async function getMemberContractsAsync(
  axios: AxiosInstance,
  params?: {
    memberNo?: string;
    isContractor?: boolean;
    isAdmin?: boolean;
    contractSteps?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<MemberContractModel> }>>> {
  if (!params?.memberNo) {
    throw Error("memberNo is not found.");
  }
  let queryParams = { ...params };
  delete queryParams["memberNo"];
  if (params.isContractor) {
    queryParams = { ...queryParams, ...{ isContractor: true } };
  }
  if (params.isAdmin) {
    queryParams = { ...queryParams, ...{ isAdmin: true } };
  }
  return await axios.get(`/api/court/ct/member/${params.memberNo}/contract`, {
    params: queryParams,
  });
}

/**
 * TCT46. 계약의 이용자 목록 조회
 */
export async function getMemberContractManageListAsync(
  axios: AxiosInstance,
  params?: MemberContractManageListParams,
): Promise<AxiosResponse<ApiResponse<Array<MemberContractManageModel>>>> {
  if (!params?.contractManageId) {
    throw Error("contractManageId is not found.");
  }
  let queryParams = {};
  if (params.searchType) {
    queryParams = { ...queryParams, ...{ searchType: params.searchType } };
  }
  if (params.searchValue) {
    queryParams = { ...queryParams, ...{ searchValue: params.searchValue } };
  }
  if (params.pageRequest) {
    queryParams = { ...queryParams, ...params.pageRequest };
  }
  return await axios.get(`/api/court/ct/member/contract-manage/${params.contractManageId}/list`, {
    params: queryParams,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * TCT47. 계약 이용자(만료상태) 재초대 처리
 */
export async function patchMemberReInviteAsync(
  axios: AxiosInstance,
  params?: {
    contractMemberId: number; // 이용자 id
  },
): Promise<AxiosResponse<ApiResponse<{ contractMemberId: number }>>> {
  if (!params?.contractMemberId) {
    throw Error("params.contractMemberId is not found.");
  }
  return await axios.patch(`/api/court/ct/member/${params.contractMemberId}/re-invite`);
}

/**
 * TCT62. 청구정보 결제현황 조회 (v1.3)
 */
// export async function getBillingContractBillsAsync(
//   axios: AxiosInstance,
//   params?: {
//     contractId: number | string;
//   },
// ): Promise<AxiosResponse<ApiResponse<BillingContractBillModel>>> {
//   if (!params?.contractId) {
//     throw Error("contractId is not found.");
//   }

//   return await axios.get(`/api/court/ct/billing/contract/${params.contractId}/bills`);
// }

/**
 * TCT63. 청구정보 결제현황 조회 ^(v1.4)
 */
export async function getBillingContractBillsAsync(
  axios: AxiosInstance,
  params?: {
    contractId: number | string;
  },
): Promise<AxiosResponse<ApiResponse<BillingContractBillModel>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found.");
  }

  return await axios.get(`/api/court/ct/contract/${params.contractId}/bills`);
}

/**
 * TCT70. 계약 프로바이더 입금계좌 조회
 */
export async function getProviderAccountsAsync(
  axios: AxiosInstance,
  params?: {
    contractApplyNumber: string; // 이용(계약)신청번호
    accountType?: AccountType; // DEPOSIT_ACCOUNT: 보증금 계좌, RENT_ACCOUNT: 이용료 계좌, MANAGEMENT_ACCOUNT: 관리비 계좌
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<CtProviderAccount> }>>> {
  if (!params?.contractApplyNumber) {
    throw Error("contractApplyNumber is not found.");
  }
  let queryParams = {
    contractApplyNumber: params.contractApplyNumber,
  };
  if (params.accountType) {
    queryParams = { ...queryParams, ...{ accountType: params.accountType } };
  }

  return await axios.get("/api/court/ct/provider/accounts", {
    params: queryParams,
  });
}

/**TCT60. 빌링스케줄 인증요청 */
export async function postBillingAuthenticationAsync(axios: AxiosInstance, params?: { scheduleId: string; envType: string; partnerCode?: string }) {
  const body = _.pick(params || {}, ["envType", "partnerCode"]);
  // const body = params?.pgcode && params?.pgcode !== "" ? { pgcode: params?.pgcode! } : {};
  console.debug("postBillingAuthenticationAsync body", body);
  return await axios.post(`/api/court/ct/schedule/${params?.scheduleId}/certify`, body);
}

/**TCT24. 거래내역 확인서 조회 */
export async function getPaymentReceiptAsync(
  axios: AxiosInstance,
  params?: { id: string },
): Promise<AxiosResponse<ApiResponse<{ receipt_url: string }>>> {
  return await axios.get(`/api/court/ct/pay/payment/${params?.id}/receipt`);
}

/**TCT22. 페이레터 청구 자동 결제 */
export async function billingAutoPayAsync(axios: AxiosInstance, params?: { id: string }): Promise<AxiosResponse<ApiResponse<AutoPayResponse>>> {
  return await axios.post(`/api/court/ct/pay/bills/${params?.id}/autopay`);
}

/** TIQ01 - (인증/무인증)견적서 목록(상세) */
export async function getQuotationList(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.get("/api/court/pc/quotation", {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/** TCT37. 이용신청(계약) 공용시설 조회 */
export async function getContractFacility(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.get(`/api/court/ct/contract/${params?.contractId}/facility`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/** TCT20. 페이레터 청구 결제 요청 */
export async function postPayletterPayment(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const body = _.pick(params || {}, ["pgcode", "envType", "partnerCode"]);
  // const body = params?.pgcode && params?.pgcode !== "" ? { pgcode: params?.pgcode! } : {};
  console.debug("postPayletterPayment body", body);
  return await axios.post(`/api/court/ct/pay/bills/${params?.billId!}/request`, body);
}

/** TCT38. 결제/취소 내역 조회 */
export async function getPaymentData(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.get(`/api/court/ct/contract/${params?.contractId}/payment`);
}

/** TCT39. 청구 결제내역 조회 */
export async function getBillsPaymentData(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  let path = `/api/court/ct/pay/bills/${params?.billId}/payment?pgcode=${params?.pgcode}`;
  if (params.pgcode === "virtualaccount") {
    path = path + `&isSuccessOnly=${false}`;
  }
  if (params.pgcode === "creditcard") {
    path = path + `&isSuccessOnly=${true}`;
  }

  return await axios.get(path);
}

/** TCT71. 계약 프로바이더 연락처 정보 조회 */
export async function contractsProviderData(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = `/api/court/ct/provider/contact`;

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/** TCT72. 프로바이더(판매자) 정보 조회 */
export async function getProviderData(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  let path = `/api/court/ct/provider/{providerId}/basic`.replace("{providerId}", params?.providerId);

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/**
 * TCT28. 지출증빙 신청정보 등록
 */
export async function postExpenseProofAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    expenseProofRequest: any;
  },
): Promise<AxiosResponse<ApiResponse<ContractAddResponseData>>> {
  if (!params?.contractId) {
    throw Error("params.contractId is not found.");
  }
  let path = "/api/court/ct/contract/{contractId}/expense-proof-request".replace("{contractId}", params.contractId);
  return await axios.post(path, { expenseProofRequest: params.expenseProofRequest });
}
/** TCT27. 지출증빙 신청정보 조회 */
export async function getExpenseProofAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = "/api/court/ct/contract/{contractId}/expense-proof-request".replace("{contractId}", params!.contractId!);

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/** TCN20. 건물 운영정보 조회 */
export async function getContractBuildingsAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = `/api/court/ct/operation/contract-manage/${params?.contractManageId}/buildings`;

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/** TCN20b. (무인증) 건물 운영정보 조회 */
export async function getPublicContractBuildingsAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = `/api/court/ct/external/operation/contract-manage/${params?.contractManageId}/buildings`;

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
//
export async function getRefundInfo(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    request: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = "/api/court/ct/contract/{contractId}/product/attributes".replace("{contractId}", params!.contractId!);

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * TCT50b. 방문자 목록 페이지 조회
 */

export async function getVisitorListAsync(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = "/api/court/ct/visitor/list";

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * TCT53. 방문자 삭제 (초대자)
 * @param axios
 * @param params
 * @returns
 */
export async function deleteVisitorsAsync(
  axios: AxiosInstance,
  params?: {
    inviteeMemberNo: string;
    contractVisitorIds: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params?.inviteeMemberNo) {
    throw Error("inviteeMemberNo is not found.");
  }
  const path = "/api/court/ct/visitor/{inviteeMemberNo}/visitor-delete".replace("{inviteeMemberNo}", params!.inviteeMemberNo);
  return await axios.patch(path, { contractVisitorIds: params!.contractVisitorIds! });
}
/**
 * TRV11. 계약 마스터 공용 공간 예약 목록 조회
 */
export async function getReservationListAsync(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  // TODO
  let path = "/api/court/rv/contracts/{contractId}/management/reservations".replace("{contractId}", params!.contractId);

  return await axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * TRV12. 계약 마스터 공용 공간 예약 취소 처리
 */

export async function deleteReservationsAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    reservationId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = "/api/court/rv/contracts/{contractId}/management/reservations/{reservationId}"
    .replace("{contractId}", params!.contractId!)
    .replace("{reservationId}", params!.reservationId!);
  return await axios.delete(path);
}
