import { VirtualAccountDetailsProps } from "../types";
import { PriceRow } from "./PriceRow";
import { VirtualAccountInfo } from "./VirtualAccountInfo";

import moment from "moment";

export const VirtualAccountDetails = ({ payment, documentProofRequest, billPayStatus, receiptBundle, onCopyAccount }: VirtualAccountDetailsProps) => {
  const isPaymentSuccess = billPayStatus === "PAYMENT_SUCCESS";

  return (
    <>
      <PriceRow label="상품금액" value={payment.totalAmount - payment.taxAmount} />
      <PriceRow label="부가세" value={payment.taxAmount} />
      <PriceRow label={"총 결제금액"} value={payment.totalAmount} isTotal />
      {payment.virtualAccount && (
        <VirtualAccountInfo
          payment={payment}
          documentProofRequest={documentProofRequest}
          virtualAccount={payment.virtualAccount}
          billPayStatus={billPayStatus}
          onCopyAccount={onCopyAccount}
        />
      )}
      {isPaymentSuccess && receiptBundle?.lastTrDateTime && (
        <>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">계좌번호</p>
            <p
              className="base-section-layout-value text-underline cursor-pointer"
              onClick={() => onCopyAccount(String(payment?.virtualAccount?.accountNo))}
            >
              {payment?.virtualAccount?.bankName} {payment?.virtualAccount?.accountNo}
            </p>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">결제일시</p>
            <p className="base-section-layout-value">{moment(receiptBundle?.lastTrDateTime).format("YYYY-MM-DD HH:mm")}</p>
          </div>
        </>
      )}
    </>
  );
};
