import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import Header from "src/components/layout/Header";
type Seller = {
  provider: any;
  providerContactList: any;
};

const SellerModal = () => {
  const navigate = usePartnerNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sellerData = JSON.parse(decodeURIComponent(searchParams.get("data") || "{}"));
  const onClose = () => {
    navigate(-1);
  };
  const [modalData] = useState<Seller>(sellerData);

  const pcl = useMemo(() => {
    let rtn: any = {};
    if (sellerData && sellerData.providerContactList) {
      const finded = sellerData.providerContactList.find((item: any) => item.assignedWorkType === "CS_PROVIDE");

      if (finded) {
        return finded;
      } else {
        return rtn;
      }
    }
  }, [sellerData]);
  return (
    <div className="modal-container">
      <Header
        headerType="BACK"
        title={"판매자 정보"}
        onClickBackButton={() => {
          onClose()!;
        }}
      />
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__title-wrap">
            <h2>{"판매자 정보"}</h2>
          </div>
          <ul className="mb30 ml10 mr10 font15 text-primary1">
            <li>{"시간제 예약 상품에 한정하여 취소 규정은 이용일 기준으로 적용됩니다."}</li>
            <li>{"이용기간 및 상품 예약 정보 변경을 위한 취소 시에도 동일한 취소 규정이 적용됩니다."}</li>
          </ul>
          <table className="horizontal-table font14">
            <tbody>
              <tr>
                <td className="minmax160">사업자명</td>
                <td>{modalData?.provider?.providerName}</td>
              </tr>
              <tr>
                <td>대표자명</td>
                <td>{modalData?.provider?.representative}</td>
              </tr>
              <tr>
                <td>사업자 등록번호</td>
                <td>{modalData?.provider?.businessRegistrationNumber}</td>
              </tr>
              <tr>
                <td>주소</td>
                <td>
                  <p className="ellipsis2">{modalData?.provider?.address}</p>
                </td>
              </tr>
              <tr>
                <td>연락처</td>
                <td>{pcl?.contact}</td>
              </tr>
              <tr>
                <td>이메일</td>
                <td>{pcl?.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
export default SellerModal;
