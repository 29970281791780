import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { route } from "src/vars";

/**
 * 루트 화면
 */
const Root = () => {
  const navigate = usePartnerNavigate();
  const isOnlyServePage = process.env.REACT_APP_ONLY_SERVE_PAGE;

  useEffect(() => {
    isOnlyServePage ? navigate(`${route.soon}${window.location.search}`) : navigate(`${route.mainPath}${window.location.search}`);
  }, []);

  return null;
};
export default Root;
